import React, {useRef, useEffect, useState, Suspense} from 'react';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import StrategyPage from './pages/StrategyPage';
import EndingPage from './pages/EndingPage';
import ContactUs from './pages/ContactUs';
import Header from './components/Header';
import './App.css';
import './sass/_base.scss';


const App = () => {
  return (
    <div className="appWrapper">


      <BrowserRouter>
        <Header />
        
        <Routes>

          <Route path="/lp/autox/landing" element={<LandingPage />} exact />
          <Route path="/lp/autox" element={<LandingPage />} exact />
          <Route path="/lp/autox/strategy/:strategyType" element={<StrategyPage />} exact />
          <Route path="/lp/autox/ending" element={<EndingPage />} exact />
          <Route path="/lp/autox/contact-us" element={<ContactUs />} exact />


        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
