/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function animateLandingCards(orderRouteRef1, orderRouteRef2, orderRouteRef3, orderRouteRef4) {
    orderRouteRef1.current.goToAndStop(0, true);
    orderRouteRef2.current.goToAndStop(0, true);
    orderRouteRef3.current.goToAndStop(0, true);
    orderRouteRef4.current.goToAndStop(0, true);


    const landingCardsTimeline = gsap.timeline({
        scrollTrigger: {
            trigger: ".autoX__landingAnimationsWrapper",
            start:"bottom bottom",
        },
    });
    landingCardsTimeline.add('landingCardsStart')
    .add(() => orderRouteRef1.current.play(), 'landingCardsStart')
    .add(() => orderRouteRef2.current.play(), 'landingCardsStart')
    .add(() => orderRouteRef3.current.play(), 'landingCardsStart')
    .add(() => orderRouteRef4.current.play(), 'landingCardsStart')
    
}
