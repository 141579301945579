/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import React, {useState} from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(MotionPathPlugin);

let pastTimelines = [];
let optionSelected = false;
let moreMoneySelected = false;
let lessMoneySelected = false;

export function animateProvideStrategyBrainMap() {


    // Hide profile pictures for other two strategies
    gsap.set("#avatar_1_group, #avatar_2_group", {opacity: 0});


    // Set handler for slider option card
    document.querySelector("#provideOptionSlider").addEventListener("change", (e) => {
        if(e.target.value >= 51 && !optionSelected && !moreMoneySelected) {
            optionSelected = true;
            moreMoneySelected = true;
            lessMoneySelected = false;
            startMoreMoneyAnimation();
        } else if(e.target.value >= 51 && optionSelected && !moreMoneySelected) {
            lessMoneySelected = false;
            moreMoneySelected = true;
            previousAnimationStep(null, true);
            setTimeout(() => {
                startMoreMoneyAnimation();
            }, 1000)
        } else if(e.target.value <= 49 && !optionSelected && !lessMoneySelected) {
            optionSelected = true;
            moreMoneySelected = false;
            lessMoneySelected = true;
            startLessMoneyAnimation();
        } else if(e.target.value <= 49 && optionSelected && !lessMoneySelected) {
            lessMoneySelected = true;
            moreMoneySelected = false;
            previousAnimationStep(null, true);
            setTimeout(() => {
                startLessMoneyAnimation();
            }, 1000)
        } else if(e.target.value > 49 && e.target.value < 51 && optionSelected) {
            optionSelected = false;
            lessMoneySelected = false;
            moreMoneySelected = false;
            previousAnimationStep();
        }
    });

    document.querySelector("#autoX__strategyActionButton").addEventListener("click", startAnimation);
    document.querySelector("#autoX__strategyBackNav").addEventListener("click", previousAnimationStep);
    document.querySelector("#provideStrategyOptionButton1").addEventListener("click", pauseOrderAnimation);
    document.querySelector("#provideStrategyOptionButton2").addEventListener("click", resumeOrderAnimation);
    document.querySelector("#provideStrategyNextButtonMore").addEventListener("click", endingAnimationMore);
    document.querySelector("#provideStrategyNextButtonLess").addEventListener("click", endingAnimationLess);
    document.querySelector("#provideStrategyEndingButton").addEventListener("click", endingAnimation);
}

function startAnimation() {
    // Main timeline
    const seekStrategyTimeline1 = gsap.timeline({});
    pastTimelines.push(seekStrategyTimeline1);

    gsap.set("#spinner_1, #spinner_2, #spinner_3, #spinner_4, #spinner_5, #spinner_6, #spinner_7, #spinner_8, #spinner_9, #spinner_10, #spinner_11, #spinner_12, #spinner_13, #spinner_14, #spinner_15, #spinner_16, #spinner_17, #spinner_18, #spinner_19", {opacity: 0});

    // Spinners Fade Away Timeline
    const seekStrategyTimeline2 = gsap.timeline({});
    setupStrategyTimeline2(seekStrategyTimeline2);

    seekStrategyTimeline1.add('seekStrategyStart')
    .to("#autoX__strategyActionButton", {y: -100, opacity: 0, display: 'none', duration: 0.6}, 'seekStrategyStart')
    .fromTo("#takeStrategyPrompt1", {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.6}, 'seekStrategyStart+=0.3')
    .fromTo("#headerProgressDot", {opacity: 0, scale: 0, transformOrigin: "center"}, {opacity: 1, scale: 1, duration: 0.6}, 'seekStrategyStart')
    .fromTo("#headerProgressDot", {left: "0%", ease: 'linear'}, {left: '34%', duration: 9}, 'seekStrategyStart+=0.3')
    .to("#headerProgressAnimatedLine", {width: "34%", duration: 6, ease: 'linear'}, 'seekStrategyStart+=0.5')
    // Status Orb to "loading" state
    .fromTo("#statusOrb", {opacity: 0, display: "block"}, {opacity: 1, duration: 0.6}, 'seekStrategyStart+=0.5')
    .fromTo("#x-orb", {scale: 1, transformOrigin: "center"}, {scale: 1.4, duration: 0.6}, 'seekStrategyStart+=0.5')
    .fromTo("#loading",{opacity: 0}, {opacity: 1, duration: 0.6,}, 'seekStrategyStart+=0.8')
    .fromTo("#x-loading",{scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.6,}, 'seekStrategyStart+=0.6')
    .to("#loading", {transformOrigin: "center", duration: 0.8, rotation:"+=360",repeat:-1, ease: 'linear'}, 'seekStrategyStart+=0.6')
    .fromTo("#autoX__takeStrategyStatusText1", {opacity: 0, y: 100, display: "block"}, {opacity: 1, y: 0, duration: 0.6}, 'seekStrategyStart+=0.6')
    // Spinners
    .call(() => {
        startSpinnerAnimations(seekStrategyTimeline1, 'seekStrategyStart', 1.5, 0.05);
    }, null, 'seekStrategyStart+=1.5')
    .add(seekStrategyTimeline2, 'seekStrategyStart+=3.5')
}

function setupStrategyTimeline2(timeline) {
    MotionPathPlugin.convertToPath("#order_path_solid_3b");

    timeline.add('spinnersFadeAway')
    .fromTo("#spinner_1", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2')
    .fromTo("#spinner_2", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.05')
    .fromTo("#spinner_3", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.1')
    .fromTo("#spinner_4", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.15')
    .fromTo("#spinner_5", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.2')
    .fromTo("#spinner_6", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.25')
    .fromTo("#spinner_7", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.3')
    .fromTo("#spinner_8", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.35')
    .fromTo("#spinner_9", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.4')
    .fromTo("#spinner_10", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.45')
    .fromTo("#spinner_11", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.5')
    .fromTo("#spinner_12", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.55')
    .fromTo("#spinner_13", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.6')
    .fromTo("#spinner_14", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.65')
    .fromTo("#spinner_15", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.7')
    .fromTo("#spinner_16", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.75')
    .fromTo("#spinner_17", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.8')
    .fromTo("#spinner_18", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.85')
    .fromTo("#spinner_19", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.9')
    // Transition status text
    .call(() => {
        transitionStatusText(timeline,"#autoX__takeStrategyStatusText1", "#autoX__takeStrategyStatusText2", 'spinnersFadeAway+=3', 'spinnersFadeAway+=3.4');
    }, null, 'spinnersFadeAway+=2')
    // Status Orb to "loading" state
    .fromTo("#loading",{opacity: 1}, {opacity: 0, duration: 0.8,}, 'spinnersFadeAway+=3')
    .fromTo("#x-loading",{scale: 1, transformOrigin: "center"}, {scale: 1.4, duration: 0.8,}, 'spinnersFadeAway+=3')
    // Animate beginning of path 2b first
    .call(() => {
        animatedDottedLine(timeline, "#order_dotted_3b", "#order_path_dotted_3b_mask_path", "0%", "15%", 0.4, 'spinnersFadeAway+=3');
    }, null, 'spinnersFadeAway+=3')
    // Animate the three paths
    .call(() => {
        animatedDottedLine(timeline, "#order_dotted_3a", "#order_path_dotted_3a_mask_path", "0%", "45%", 2, 'spinnersFadeAway+=3.4', 'power2.out');
    }, null, 'spinnersFadeAway+=3.4')
    .call(() => {
        animatedDottedLine(timeline, "#order_dotted_3b", "#order_path_dotted_3b_mask_path", "15%", "38%", 1.4, 'spinnersFadeAway+=5', 'power2.out');
    }, null, 'spinnersFadeAway+=5')
    .call(() => {
        animatedDottedLine(timeline, "#order_dotted_3c", "#order_path_dotted_3c_mask_path", "0%", "85%", 2, 'spinnersFadeAway+=6', 'power2.out');
    }, null, 'spinnersFadeAway+=6')
    .fromTo("#check_mark_1a",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=3.4')

    // path 2a checkmarks
    .fromTo("#check_mark_2a",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=3.6')
    .fromTo("#check_mark_3a",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=4')
    .fromTo("#check_mark_2c",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=4.8')
    // .fromTo("#check_mark_2d",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=7')

    // Path 2b checkmarks
    .fromTo("#check_mark_1b",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=5.4')
    .fromTo("#check_mark_1c",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=6.2')

    // Path 2c checkmarks
    .fromTo("#check_mark_2g",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=6.2')
    .fromTo("#check_mark_3c",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=6.4')
    .fromTo("#check_mark_3d",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=6.6')
    .fromTo("#check_mark_3e",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=7.8')

    // Start solid lines animation
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_3b", "#order_path_solid_3b", "0%", "15%", 0.8, 'spinnersFadeAway+=8' , 'power2.out');
    }, null, 'spinnersFadeAway+=8')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_3a", "#order_path_solid_3a", "0%", "45%", 1.4, 'spinnersFadeAway+=8.8', 'power2.out');
    }, null, 'spinnersFadeAway+=8.8')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_3b", "#order_path_solid_3b", "15%", "38%", 1.2, 'spinnersFadeAway+=9.2', 'power2.out');
    }, null, 'spinnersFadeAway+=9.2')
    // .call(() => {
    //     animatedDottedLine(timeline, "#order_solid_3c", "#order_path_solid_3c", "0%", "85%", 1.4, 'spinnersFadeAway+=9.8');
    // }, null, 'spinnersFadeAway+=9.8')
    // Move nav arrow along path 2b
    .to("#nav_arrow_group_1a", {opacity: 1, duration: 0.1}, 'spinnersFadeAway+=9.2')
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_1a", "#order_path_solid_3b", 1.8, 0, 0.30, 'spinnersFadeAway+=9.2', 'power2.inOut');
    }, null, 'spinnersFadeAway+=9.2')
    .to("#check_mark_1b", {opacity: 0, scale: 0, duration: 0.3}, 'spinnersFadeAway+=10.6')
    // Show glowing waiting dot on path 2b
    .to("#check_mark_1c", {opacity: 0, scale: 0, duration: 0.4}, 'spinnersFadeAway+=11')
    .fromTo("#waiting_dot_3a", {opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1.4, duration: 0.6}, 'spinnersFadeAway+=11')
    .fromTo("#waiting_dot_3a", {boxShadow: "0px 0px 20px #FFDD00"}, {boxShadow: "0px 0px 8px #FFDD00", duration: 2, repeat: -1, ease: "linear"}, 'spinnersFadeAway+=11')
    // Transition status text & show x-orb glow
    .call(() => {
        transitionStatusText(timeline,"#autoX__takeStrategyStatusText2", "#autoX__takeStrategyStatusText3", 'spinnersFadeAway+=12', 'spinnersFadeAway+=12.4');
    }, null, 'spinnersFadeAway+=12')
    .fromTo("#yellow-glow",{opacity: 0}, {opacity: 1, duration: 0.6}, 'spinnersFadeAway+=12')
    // .fromTo("#yellow-glow", {scale: 0.9, transformOrigin: "center", ease: "linear"}, {scale: 1, duration: 1, repeat: -1, yoyo: true}, 'spinnersFadeAway+=12')
    // Show first option slider card
    .fromTo("#provideStrategyOption1", {opacity: 0, y: 100}, {opacity: 1, y: 0, display: 'flex', duration: 0.6}, 'spinnersFadeAway+=12')
    .to("#takeStrategyPromptDottedLine1", {opacity: 1}, 'spinnersFadeAway+=12')
}


function pauseOrderAnimation() {
    const pauseOrderTimeline = gsap.timeline({});
    pastTimelines.push(pauseOrderTimeline);

    pauseOrderTimeline.add('pauseOrderStart')
    .fromTo("#headerProgressDot", {left: "34%", ease: 'linear'}, {left: '52%', duration: 9}, 'pauseOrderStart')
    .to("#headerProgressAnimatedLine", {width: "52%", duration: 6, ease: 'linear'}, 'pauseOrderStart')
    .call(() => {
        transitionStatusText(pauseOrderTimeline, "#autoX__takeStrategyStatusText3", "#autoX__takeStrategyStatusText4", 'pauseOrderStart', 'pauseOrderStart+=0.4');
    }, null, 'pauseOrderStart')
    .to("#autoX__strategyActionsCardText-1-1", {opacity: 0, display: "none", duration: 0.4}, 'pauseOrderStart')
    .to("#autoX__strategyActionsCardText-1-2", {opacity: 1, display: "block", duration: 0.4}, 'pauseOrderStart+=0.4')
    // Animate waiting dotted line
    .to("#yellow-glow", {opacity: 0, duration: 0.6}, 'pauseOrderStart+=1')
    .to("#waiting_dot_3a_outline", {opacity: 1, duration: 0.1}, 'pauseOrderStart+=1')
    .fromTo("#waiting_dot_3a_dotted_outline_mask_path", {
        drawSVG: "0%",
    }, {
        drawSVG: "100%",
        duration: 0.8,
        ease: 'linear'
    }, 'pauseOrderStart+=1')
    .to("#waiting_dot_3a_dotted_outline_mask", {display: 'none', duration: 0.1},'pauseOrderStart+=1.7')
    .fromTo("#waiting_dot_3a_outline", {scale: 1.3, transformOrigin: "center"}, {scale: 1},'pauseOrderStart+=1.8')
    // Animate status orb dotted line
    .to("#dotted-line-start", {opacity: 1, duration: 0.1}, 'pauseOrderStart+=1')
    .fromTo("#dotted-line-start-mask-path", {
        drawSVG: "0%",
    }, {
        drawSVG: "100%",
        duration: 0.8,
        ease: 'linear'
    }, 'pauseOrderStart+=1')
    .to("#dotted-line-start-mask", {display: 'none', duration: 0.1}, 'pauseOrderStart+=1.8')
    .fromTo("#x-orb", {scale: 1.4}, {scale: 1.1, duration: 0.8}, 'pauseOrderStart+=2')
    .fromTo("#dotted-line-start-path", {scale: 1, strokeWidth: 2, transformOrigin: "center"}, {strokeWidth: 4, scale: 0.85}, 'pauseOrderStart+=2')
    // Reset status orb and waiting dotted line
    .to("#waiting_dot_3a_outline", {opacity: 0, duration: 0.6}, 'pauseOrderStart+=6')
    .to("#x-orb", {scale: 1.4, duration: 0.8, transformOrigin: "center"}, 'pauseOrderStart+=6')
    .to("#dotted-line-start-path", {opacity: 0, duration: 0.6}, 'pauseOrderStart+=6')
    // Continue path A and B dotted lines
    .call(() => {
        animatedDottedLine(pauseOrderTimeline, "#order_dotted_3a", "#order_path_dotted_3a_mask_path", "45%", "68%", 1.8, 'pauseOrderStart+=6', 'power1.out');
    }, null, 'pauseOrderStart+=6')
    .call(() => {
        animatedDottedLine(pauseOrderTimeline, "#order_dotted_3b", "#order_path_dotted_3b_mask_path", "38%", "74%", 1.4, 'pauseOrderStart+=6', 'power1.out');
    }, null, 'pauseOrderStart+=6')
    // Show checkmarks
    .fromTo("#check_mark_2d",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'pauseOrderStart+=7.6')
    // Show next status text
    .call(() => {
        transitionStatusText(pauseOrderTimeline, "#autoX__takeStrategyStatusText4", "#autoX__takeStrategyStatusText5", 'pauseOrderStart+=8', 'pauseOrderStart+=8.4');
    }, null, 'pauseOrderStart+=8')
    // Show status orb yellow glow
    .fromTo("#yellow-glow", {opacity: 0}, {opacity: 1, duration: 0.6}, 'pauseOrderStart+=8')
    // .fromTo("#yellow-glow", {scale: 0.9, transformOrigin: "center", ease: "linear"}, {scale: 1, duration: 1, repeat: -1, yoyo: true}, 'pauseOrderStart+=8')
    // Show second option slider card
    .to("#autoX__strategyActionsCardText-1-2", {opacity: 0, display: "none", duration: 0.4}, 'pauseOrderStart+=8')
    .to("#autoX__strategyActionsCardText-1-3", {opacity: 1, display: "block", duration: 0.4}, 'pauseOrderStart+=8.4')
    .fromTo("#provideStrategyOptionButton2", {opacity: 0, y: 100, display: "none"}, {opacity: 1, y: 0, display: "flex", duration: 0.4}, 'pauseOrderStart+=8.8')
}

function resumeOrderAnimation() {
    const resumeOrderTimeline = gsap.timeline({});
    pastTimelines.push(resumeOrderTimeline);

    resumeOrderTimeline.add('resumeOrderStart')
    .fromTo("#headerProgressDot", {left: "52%", ease: 'linear'}, {left: '74%', duration: 9}, 'resumeOrderStart')
    .to("#headerProgressAnimatedLine", {width: "74%", duration: 6, ease: 'linear'}, 'resumeOrderStart')
    .to("#yellow-glow", {opacity: 0, duration: 0.6}, 'resumeOrderStart')
    .call(() => {
        transitionStatusText(resumeOrderTimeline, "#autoX__takeStrategyStatusText5", "#autoX__takeStrategyStatusText6", 'resumeOrderStart', 'resumeOrderStart+=0.4');
    }, null, 'resumeOrderStart')
    .to("#provideStrategyOption1", {opacity: 0, display: "none", duration: 0.6}, 'resumeOrderStart')
    .to("#takeStrategyPromptDottedLine1", {opacity: 0, duration: 0.6}, 'resumeOrderStart')
    .to("#waiting_dot_3a", {opacity: 0, duration: 0.6}, 'resumeOrderStart')
    .fromTo("#check_mark_1c",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'resumeOrderStart+=0.6')
    .fromTo("#check_mark_3b",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'resumeOrderStart+=1')
    .fromTo("#check_mark_1f",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'resumeOrderStart+=1.6')
    // Status Orb to "loading" state
    .fromTo("#x-orb", {scale: 1.4, transformOrigin: "center"}, {scale: 0, duration: 0.01}, 'resumeOrderStart')
    .fromTo("#x-orb", {scale: 0, transformOrigin: "center"}, {scale: 1.4, duration: 0.6}, 'resumeOrderStart+=0.1')
    .fromTo("#loading",{opacity: 0}, {opacity: 1, duration: 0.6,}, 'resumeOrderStart+=1.3')
    // .fromTo("#x-loading",{scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.6,}, 'resumeOrderStart+=1.3')
    .to("#loading", {transformOrigin: "center", duration: 0.8, rotation:"+=360",repeat: -1, ease: 'linear'}, 'resumeOrderStart+=1.3')
    // Resume solid lines for paths A and B
    .call(() => {
        animatedDottedLine(resumeOrderTimeline, "#order_solid_3a", "#order_path_solid_3a", "45%", "68%", 1.4, 'resumeOrderStart+=2', 'power1.out');
    }, null, 'resumeOrderStart+=2')
    .call(() => {
        animatedDottedLine(resumeOrderTimeline, "#order_solid_3b", "#order_path_solid_3b", "38%", "52%", 1.3, 'resumeOrderStart+=3' , 'power1.out');
    }, null, 'resumeOrderStart+=3')
    // Move nav arrow
    .call(() => {
        moveNavArrow(resumeOrderTimeline, "#nav_arrow_group_1a", "#order_path_solid_3b", 1.4, 0.30, 0.49, 'resumeOrderStart+=3.2', 'power2.inOut');
    }, null, 'resumeOrderStart+=3.2')
    // Show waiting yellow dot
    .fromTo("#waiting_dot_3b", {opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1.4, duration: 0.6}, 'resumeOrderStart+=4.6')
    .fromTo("#waiting_dot_3b", {boxShadow: "0px 0px 20px #FFDD00"}, {boxShadow: "0px 0px 8px #FFDD00", duration: 2, repeat: -1, ease: "linear"}, 'resumeOrderStart+=4.6')
    // Hide dotted line for path B and checkmark 1f
    .to("#check_mark_1f", {opacity: 0, scale: 0, duration: 0.3}, 'resumeOrderStart+=5')
    .to("#order_dotted_3b", {opacity: 0, duration: 0.6}, 'resumeOrderStart+=5')
    // Show option card 2
    .fromTo("#provideStrategyOption2", {opacity: 0, y: 100}, {opacity: 1, y: 0, display: 'flex', duration: 0.6}, 'resumeOrderStart+=5.6')
    .to("#takeStrategyPromptDottedLine1", {opacity: 1}, 'resumeOrderStart+=5.6')
    // Transition status text
    .call(() => {
        transitionStatusText(resumeOrderTimeline, "#autoX__takeStrategyStatusText6", "#autoX__takeStrategyStatusText7", 'resumeOrderStart+=5.6', 'resumeOrderStart+=6');
    }, null, 'resumeOrderStart+=6')
    // Status orb to waiting state
    .fromTo("#loading",{opacity: 1}, {opacity: 0, duration: 0.8,}, 'resumeOrderStart+=6')
    .fromTo("#x-loading",{scale: 1, transformOrigin: "center"}, {scale: 1.4, duration: 0.8,}, 'resumeOrderStart+=6')
    .fromTo("#yellow-glow",{opacity: 0}, {opacity: 1, duration: 0.6}, 'resumeOrderStart+=6.8')
}

function startMoreMoneyAnimation() {
    const moreMoneyTimeline = gsap.timeline({});
    pastTimelines.push(moreMoneyTimeline);

    moreMoneyTimeline.add('moreMoneyStart')
    .to("#order_dotted_3b", {opacity: 0, duration: 0.8}, 'moreMoneyStart')
    .to("#reattempt_dotted_3b", {opacity: 1, duration: 0.8}, 'moreMoneyStart')
    .fromTo("#provideStrategyNextButtonMore", {opacity: 0, y: 100}, {opacity: 1, y: 0, display: "flex", duration: 0.4}, 'moreMoneyStart')
}

function startLessMoneyAnimation() {
    const lessMoneyTimeline = gsap.timeline({});
    pastTimelines.push(lessMoneyTimeline);

    lessMoneyTimeline.add('lessMoneyStart')
    .to("#reattempt_dotted_3b", {opacity: 0, duration: 0.8}, 'lessMoneyStart')
    .to("#order_dotted_3b", {opacity: 1, duration: 0.8}, 'lessMoneyStart')
    .fromTo("#provideStrategyNextButtonLess", {opacity: 0, y: 100}, {opacity: 1, y: 0, display: "flex", duration: 0.4}, 'lessMoneyStart')
    
}


function endingAnimationLess() {
    const timeline = gsap.timeline({});
    pastTimelines.push(timeline);

    timeline.add('endingAnimationStart')
    // Remove waiting dot and show checkmark again
    .fromTo("#headerProgressDot", {left: "74%", ease: 'linear'}, {left: '90%', duration: 9}, 'endingAnimationStart')
    .to("#headerProgressAnimatedLine", {width: "90%", duration: 6, ease: 'linear'}, 'endingAnimationStart')
    // Hide status orb yellow glow
    .to("#yellow-glow", {opacity: 0, duration: 0.6}, 'endingAnimationStart')
    .to("#waiting_dot_3b", {opacity: 1, scale: 0, transformOrigin: "center"}, {opacity: 0, scale: 0, duration: 0.6}, 'endingAnimationStart')
    .to("#reattempt_dotted_3b", {opacity: 0, duration: 0.8}, 'endingAnimationStart')
    // Remove slider option card and next button
    .fromTo("#provideStrategyNextButtonLess", {opacity: 1, x: 0}, {opacity: 0, x: 100, display: "none", duration: 0.6}, 'endingAnimationStart+=0.4')
    .fromTo("#provideStrategyOption2", {opacity: 1, x: 0}, {opacity: 0, x: 100, display: "none", duration: 0.6}, 'endingAnimationStart+=0.4')
    .fromTo("#provideStrategyOption3", {opacity: 0, y: 100}, {opacity: 1, y: 0, display: 'flex', duration: 0.6}, 'endingAnimationStart+=1')
    .call(() => {
        transitionStatusText(timeline, "#autoX__takeStrategyStatusText7", "#autoX__takeStrategyStatusText8", 'endingAnimationStart+=1', 'endingAnimationStart+=1.4');
    }, null, 'endingAnimationStart+=1')
    // Fill out solid line paths
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_3a", "#order_path_solid_3a", "68%", "100%", 2, 'endingAnimationStart+=2', 'power2.out');
    }, null, 'endingAnimationStart+=2')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_3b", "#order_path_solid_3b", "52%", "100%", 2, 'endingAnimationStart+=2', 'power2.out');
    }, null, 'endingAnimationStart+=2')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_3c", "#order_path_solid_3c", "0%", "100%", 2, 'endingAnimationStart+=2');
    }, null, 'endingAnimationStart+=2')
    // Move nav arrow
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_1a", "#order_path_solid_3b", 2.8, 0.49, 0.94, 'endingAnimationStart+=2.2');
    }, null, 'endingAnimationStart+=2.2')
    // Show success checkmark
    .fromTo("#green-glow", {opacity: 0}, {opacity: 1, duration: 0.6}, 'endingAnimationStart+=4.8')
    .fromTo("#success_check_front", {opacity: 0, scale: 0, transformOrigin: "center"}, {opacity: 1, scale: 1.2, duration: 0.4}, 'endingAnimationStart+=5')
    .to("#success_check_front", { scale: 1, duration: 0.2, transformOrigin: "center"}, 'endingAnimationStart+=5.4')
    .fromTo("#success_check_back", {opacity: 0, scale: 0, transformOrigin: "center"}, {opacity: 1, scale: 1.2, duration: 0.4}, 'endingAnimationStart+=5')
    .to("#success_check_back", { scale: 1, duration: 0.2, transformOrigin: "center"}, 'endingAnimationStart+=5.4')
    // Show next button
    .fromTo("#provideStrategyEndingButton", {opacity: 0, y: 100}, {opacity: 1, y: 0, display: "flex", duration: 0.6}, 'endingAnimationStart+=5.4')
}

function endingAnimationMore() {
    MotionPathPlugin.convertToPath("#reattempt_line_solid_3b");
    MotionPathPlugin.convertToPath("#order_path_solid_3a");

    const timeline = gsap.timeline({});
    // timeline.timeScale(0.4);
    pastTimelines.push(timeline);

    timeline.add('endingAnimationStart')
    // Remove waiting dot and show checkmark again
    .fromTo("#headerProgressDot", {left: "74%", ease: 'linear'}, {left: '90%', duration: 9}, 'endingAnimationStart')
    .to("#headerProgressAnimatedLine", {width: "90%", duration: 6, ease: 'linear'}, 'endingAnimationStart')
    // Hide status orb yellow glow
    .to("#yellow-glow", {opacity: 0, duration: 0.6}, 'endingAnimationStart')
    .to("#waiting_dot_3b", {opacity: 1, scale: 0, transformOrigin: "center"}, {opacity: 0, scale: 0, duration: 0.6}, 'endingAnimationStart')
    .to("#reattempt_dotted_3b", {opacity: 0, duration: 0.8}, 'endingAnimationStart')
    // Remove slider option card and next button
    .fromTo("#provideStrategyNextButtonMore", {opacity: 1, x: 0}, {opacity: 0, x: 100, display: "none", duration: 0.6}, 'endingAnimationStart+=0.4')
    .fromTo("#provideStrategyOption2", {opacity: 1, x: 0}, {opacity: 0, x: 100, display: "none", duration: 0.6}, 'endingAnimationStart+=0.4')
    .fromTo("#provideStrategyOption3", {opacity: 0, y: 100}, {opacity: 1, y: 0, display: 'flex', duration: 0.6}, 'endingAnimationStart+=1')
    .call(() => {
        transitionStatusText(timeline, "#autoX__takeStrategyStatusText7", "#autoX__takeStrategyStatusText8", 'endingAnimationStart+=1', 'endingAnimationStart+=1.4');
    }, null, 'endingAnimationStart+=1')
    // Fill out solid line paths
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_3a", "#order_path_solid_3a", "68%", "100%", 2, 'endingAnimationStart+=2', 'power2.out');
    }, null, 'endingAnimationStart+=2')
    .call(() => {
        animatedDottedLine(timeline, "#reattempt_solid_3b", "#reattempt_line_solid_3b", "0%", "100%", 1, 'endingAnimationStart+=2', 'power2.out');
    }, null, 'endingAnimationStart+=2')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_3c", "#order_path_solid_3c", "0%", "100%", 2, 'endingAnimationStart+=2');
    }, null, 'endingAnimationStart+=2')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_3b_end", "#order_path_solid_3b_end", "0%", "100%", 1, 'endingAnimationStart+=4', 'power2.out');
    }, null, 'endingAnimationStart+=4')
    // Move nav arrow
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_1a", "#order_path_solid_3b", 0.2, 0.49, 0.51, 'endingAnimationStart+=2.2');
    }, null, 'endingAnimationStart+=2.2')
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_1a", "#reattempt_line_solid_3b", 1.2, 0, 1, 'endingAnimationStart+=2.4');
    }, null, 'endingAnimationStart+=2.4')
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_1a", "#order_path_solid_3a", 0.8, 0.80, 1, 'endingAnimationStart+=3.6');
    }, null, 'endingAnimationStart+=3.6')
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_1a", "#order_path_solid_3b", 0.3, 0.89, 0.94, 'endingAnimationStart+=4.4');
    }, null, 'endingAnimationStart+=4.4')
    // Show success checkmark
    .fromTo("#green-glow", {opacity: 0}, {opacity: 1, duration: 0.6}, 'endingAnimationStart+=4.8')
    .fromTo("#success_check_front", {opacity: 0, scale: 0, transformOrigin: "center"}, {opacity: 1, scale: 1.2, duration: 0.4}, 'endingAnimationStart+=4.8')
    .to("#success_check_front", { scale: 1, duration: 0.2, transformOrigin: "center"}, 'endingAnimationStart+=5.2')
    .fromTo("#success_check_back", {opacity: 0, scale: 0, transformOrigin: "center"}, {opacity: 1, scale: 1.2, duration: 0.4}, 'endingAnimationStart+=4.8')
    .to("#success_check_back", { scale: 1, duration: 0.2, transformOrigin: "center"}, 'endingAnimationStart+=5.2')
    // Show next button
    .fromTo("#provideStrategyEndingButton", {opacity: 0, y: 100}, {opacity: 1, y: 0, display: "flex", duration: 0.6}, 'endingAnimationStart+=5.2')
}

function endingAnimation() {
    const timeline = gsap.timeline({});
    pastTimelines.push(timeline);

    timeline.add('endingAnimationStart')
    .to("#headerProgressDot", {left: '98%', duration: 2.4}, 'endingAnimationStart+=0.5')
    .to("#headerProgressAnimatedLine", {width: "94%", duration: 2.4, ease: 'linear'}, 'endingAnimationStart+=0.5')
    .to("#headerProgressDot", {opacity: 0, duration: 0.2}, 'endingAnimationStart+=2.7')
    .call(() => {
        const progressStep = document.getElementById("autoX__headerProgressStep4");
        if(progressStep.className.includes('autoX__headerProgressStep--active')) {
            progressStep.classList.remove('autoX__headerProgressStep--active');
        } else {
            progressStep.classList.add('autoX__headerProgressStep--active');
        }
    }, null, 'endingAnimationStart+=2.7')
    .fromTo("#provideStrategyEndingButton", {opacity: 1, x: 0}, {opacity: 0, x: 100, display: "none", duration: 0.6}, 'endingAnimationStart')
    .to("#provideStrategyOption3", {opacity: 0, display: "none", duration: 0.6}, 'endingAnimationStart')
    .to("#takeStrategyPrompt1", {opacity: 0, display: "none", duration: 0.6}, 'endingAnimationStart')
    .to("#takeStrategyPromptDottedLine1", {opacity: 0, display: "none", duration: 0.6}, 'endingAnimationStart')
    .call(() => {
        transitionStatusText(timeline, "#autoX__takeStrategyStatusText8", "#autoX__takeStrategyStatusText9", 'endingAnimationStart+=0.6', 'endingAnimationStart+=1');
    }, null, 'endingAnimationStart+=0.6')
    .fromTo("#provideStrategyOption4", {opacity: 0, y: 100}, {opacity: 1, y: 0, display: 'flex', duration: 0.6}, 'endingAnimationStart+=0.6')
}

function previousAnimationStep(e, sliderToggled = false) {
    const button = document.querySelector("#autoX__strategyBackNav");
    button.disabled = true;

    const sliderCard = document.querySelector("#provideOptionSlider");
    if(pastTimelines.length === 5) {
        pastTimelines[pastTimelines.length - 1].seek('endingAnimationStart+=5.4');
    } else if(pastTimelines.length === 4) {
        sliderCard.disabled = true;

        if(!sliderToggled) {
            optionSelected = false;
            moreMoneySelected = false;
            lessMoneySelected = false;                
        }
    } else if(pastTimelines.length === 3) {
        pastTimelines[pastTimelines.length - 1].seek('resumeOrderStart+=6.8');
    } else if(pastTimelines.length === 2) {
        pastTimelines[pastTimelines.length - 1].seek('pauseOrderStart+=8.8');
    } else if(pastTimelines.length === 1) {
        pastTimelines[pastTimelines.length - 1].seek('spinnersFadeAway+=12');
    }


    if(pastTimelines.length > 0) {
        pastTimelines[pastTimelines.length - 1].timeScale(2);
        pastTimelines[pastTimelines.length - 1].reverse();
        const currentTimelineLength = pastTimelines[pastTimelines.length - 1].time() / 2;
        pastTimelines.pop();

        // Disable back button until animation finishes
        setTimeout(() => {
            button.disabled = false;
            sliderCard.disabled = false;
        }, currentTimelineLength * 1000 );
    } else {
        window.location.href = "/lp/autox/landing";
    }
}


function startSpinnerAnimations(timeline, trigger, offset, stagger) {
    let totalOffset = offset;
    for(let i = 1; i <= 19; i++) {
        totalOffset += stagger;
        timeline.fromTo(`#spinner_${i}`, {scale: 0, transformOrigin: "center"}, {duration: 0.2, scale: 1}, trigger + '+=' + totalOffset).fromTo(`#spinner_${i}`, 
            {duration: 0.8, opacity:1, transformOrigin: "center",},
            {duration: 0.8, rotation:"+=360",repeat:-1, ease: 'linear'}
        )
    }
}

function animatedDottedLine(timeline, parentId, maskPathId, start, end, duration, timing, ease = 'linear') {
    timeline.to(parentId, {opacity: 1}, timing)
    .fromTo(maskPathId, {
        drawSVG: start,
    }, {
        drawSVG: end,
        duration: duration,
        ease: ease
    }, timing)
}

function transitionStatusText(timeline, firstTextElement, secondTextElement, firstElementTiming, secondElementTiming) {
    timeline.fromTo(firstTextElement, {opacity: 1, y:0}, {opacity: 0, y: -40, top: 0, display: "none", duration: 0.4}, firstElementTiming)
    .fromTo(secondTextElement, {opacity: 0, y: 80}, { display: "block", opacity: 1, y: 0, duration: 0.4}, secondElementTiming)
}

function moveNavArrow(timeline, arrowId, pathId, duration, start, end, timing, ease = 'linear') {
    timeline.to(arrowId, {
        duration: duration,
        ease: ease,
        motionPath: {
            path: pathId,
            align: pathId,
            type: "cubic",
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
            start: start,
            end: end
        }
    }, timing);
}
