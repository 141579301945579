/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(MotionPathPlugin);

let pastTimelines = [];

export function animateTakeStrategyBrainMap() {
    // Hide profile pictures for other two strategies
    gsap.set("#avatar_2_group, #avatar_3_group", {opacity: 0});

    document.querySelector("#autoX__strategyActionButton").addEventListener("click", startAnimation);
    document.querySelector("#takeStrategyOptionButton1").addEventListener("click", optionTwoAnimation);
    document.querySelector("#takeStrategyOptionButton2").addEventListener("click", optionOneAnimation);
    document.querySelector("#takeStrategyNextButton").addEventListener("click", endingAnimation);
    document.querySelector("#autoX__strategyBackNav").addEventListener("click", previousAnimationStep);
}

function startAnimation() {
    // Main timeline
    const takeStrategyTimeline1 = gsap.timeline({});
    pastTimelines.push(takeStrategyTimeline1);

    // Spinners Fade Away Timeline
    const takeStrategyTimeline2 = gsap.timeline({});
    // pastTimelines.push(takeStrategyTimeline2);


    // Orderlines timeline
    const takeStrategyTimeline3 = gsap.timeline({});
    // pastTimelines.push(takeStrategyTimeline3);

    setupStrategyTimeline2(takeStrategyTimeline2);
    setupStrategyTimeline3(takeStrategyTimeline3);

    gsap.set("#spinner_1, #spinner_2, #spinner_3, #spinner_4, #spinner_5, #spinner_6, #spinner_7, #spinner_8, #spinner_9, #spinner_10, #spinner_11, #spinner_12, #spinner_13, #spinner_14, #spinner_15, #spinner_16, #spinner_17, #spinner_18, #spinner_19", {opacity: 0});

    takeStrategyTimeline1.add('takeStrategyStart')
    .to("#autoX__strategyActionButton", {y: -100, opacity: 0, display: 'none', duration: 0.6}, 'takeStrategyStart')
    .fromTo("#takeStrategyPrompt1", {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.6}, 'takeStrategyStart+=0.3')
    .fromTo("#headerProgressDot", {opacity: 0, scale: 0, transformOrigin: "center"}, {opacity: 1, scale: 1, duration: 0.6}, 'takeStrategyStart')
    .fromTo("#headerProgressDot", {left: "0%", ease: 'linear'}, {left: '45%', duration: 9}, 'takeStrategyStart+=0.3')
    .to("#headerProgressAnimatedLine", {width: "46%", duration: 6, ease: 'linear'}, 'takeStrategyStart+=0.5')
    // Status Orb to "loading" state
    .fromTo("#statusOrb", {opacity: 0, display: "block"}, {opacity: 1, duration: 0.6}, 'takeStrategyStart+=0.5')
    .fromTo("#x-orb", {scale: 1, transformOrigin: "center"}, {scale: 1.4, duration: 0.6}, 'takeStrategyStart+=0.5')
    .fromTo("#loading",{opacity: 0}, {opacity: 1, duration: 0.6,}, 'takeStrategyStart+=0.8')
    .fromTo("#x-loading",{scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.6,}, 'takeStrategyStart+=0.6')
    .to("#loading", {transformOrigin: "center", duration: 0.8, rotation:"+=360",repeat: -1, ease: 'linear'}, 'takeStrategyStart+=0.6')
    .fromTo("#autoX__takeStrategyStatusText1", {opacity: 0, y: 100, display: "block"}, {opacity: 1, y: 0, duration: 0.6}, 'takeStrategyStart+=0.6')
    // Spinners
    .call(() => {
        startSpinnerAnimations(takeStrategyTimeline1, 'takeStrategyStart', 1.5, 0.05);
    }, null, 'takeStrategyStart+=1.5')
    .add(takeStrategyTimeline2, 'takeStrategyStart+=2.5')
    .add(takeStrategyTimeline3, 'takeStrategyStart+=7.5')
}

function setupStrategyTimeline2(timeline) {
    timeline.add('spinnersFadeAway')
    .fromTo("#spinner_1", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2')
    .fromTo("#spinner_2", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.05')
    .fromTo("#spinner_3", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.1')
    .fromTo("#spinner_4", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.15')
    .fromTo("#spinner_5", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.2')
    .fromTo("#spinner_6", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.25')
    .fromTo("#spinner_7", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.3')
    .fromTo("#spinner_8", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.35')
    .fromTo("#spinner_9", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.4')
    .fromTo("#spinner_10", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.45')
    .fromTo("#spinner_11", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.5')
    .fromTo("#spinner_12", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.55')
    .fromTo("#spinner_13", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.6')
    .fromTo("#spinner_14", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.65')
    .fromTo("#spinner_15", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.7')
    .fromTo("#spinner_16", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.75')
    .fromTo("#spinner_17", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.8')
    .fromTo("#spinner_18", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.85')
    .fromTo("#spinner_19", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.9')
    // Transition status text
    .call(() => {
        transitionStatusText(timeline,"#autoX__takeStrategyStatusText1", "#autoX__takeStrategyStatusText2", 'spinnersFadeAway+=4', 'spinnersFadeAway+=4.6');
    }, null, 'spinnersFadeAway+=4')
    // Status Orb to "loading" state
    .fromTo("#loading",{opacity: 1}, {opacity: 0, duration: 0.8,}, 'spinnersFadeAway+=4')
    .fromTo("#x-loading",{scale: 1, transformOrigin: "center"}, {scale: 1.4, duration: 0.8,}, 'spinnersFadeAway+=4')
}

function setupStrategyTimeline3(timeline) {
    timeline.add('takeStrategyOrderLines')
    // Draw the dotted order line
    .call(() => {
        animatedDottedLine(timeline, "#order_dotted_1", "#order_path_dotted_1_mask_path", "0%", "38%", 2, 'takeStrategyOrderLines');
    }, null, 'takeStrategyOrderLines')
    // Show check marks along the order path
    .fromTo("#check_mark_1a",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'takeStrategyOrderLines+=0.66')
    .fromTo("#check_mark_1b",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'takeStrategyOrderLines+=1.6')
    .fromTo("#check_mark_1c",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'takeStrategyOrderLines+=2')
    // Transition status text
    .call(() => {
        transitionStatusText(timeline,"#autoX__takeStrategyStatusText2", "#autoX__takeStrategyStatusText3", 'takeStrategyOrderLines+=2.4', 'takeStrategyOrderLines+=3');
    }, null, 'takeStrategyOrderLines+=2.4')
    // Draw the solid order line and waiting yellow dot
    .to("#order_solid_1", {opacity: 1}, 'takeStrategyOrderLines+=2.6')
    .fromTo("#order_path_solid_1", {opacity: 1,drawSVG: "0%"}, {drawSVG: "50.6%", duration: 1}, 'takeStrategyOrderLines+=3')
    .fromTo("#waiting_dot_1a", {opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.6}, 'takeStrategyOrderLines+=3.6')
    .fromTo("#waiting_dot_1a", {boxShadow: "0px 0px 20px #FFDD00"}, {boxShadow: "0px 0px 1px #FFDD00", duration: 2, repeat: -1, ease: "linear"}, 'takeStrategyOrderLines+=3.6')
    .to("#yellow-glow", {opacity: 1, duration: 0.6}, 'takeStrategyOrderLines+=3.6')
    // .fromTo("#yellow-glow", {scale: 0.9, transformOrigin: "center", ease: "linear"}, {scale: 1, duration: 1, repeat: -1, yoyo: true}, 'takeStrategyOrderLines+=3.6')
    // Move nav arrow along the order line
    .to("#nav_arrow_group_1a", {opacity: 1, duration: 0.1}, 'takeStrategyOrderLines+=2.8')
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_1a", "#order_path_solid_1", 2, 0, 0.44, 'takeStrategyOrderLines+=2.8');
    }, null, 'takeStrategyOrderLines+=2.8')
    // Show first two option cards
    .fromTo("#takeStrategyOption1", {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.6}, 'takeStrategyOrderLines+=3.6')
    .fromTo("#takeStrategyOption2", {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.6}, 'takeStrategyOrderLines+=4')
    .to("#autoX__strategyActionsCardText-1-1", {opacity: 1, duration: 0.6}, 'takeStrategyOrderLines+=4')
    .to("#autoX__strategyActionsCardText-1-2", {display: "none", opacity: 0, duration: 0.1}, 'takeStrategyOrderLines+=3.9')
    // Show connecting dotted lines between option cards
    .to("#takeStrategyPromptDottedLine1", {opacity: 1}, 'takeStrategyOrderLines+=4.6')
    .to("#takeStrategyPromptDottedLine2", {opacity: 1}, 'takeStrategyOrderLines+=4.6')
}

function optionOneAnimation() {
    const takeStrategyTimeline = gsap.timeline({});
    pastTimelines.push(takeStrategyTimeline);

    takeStrategyTimeline.add('takeStrategyContinue')
    // Remove yellow glow from Status Orb
    .to("#yellow-glow", {opacity: 0, duration: 0.6}, 'takeStrategyContinue')
    // Animate the progress bar in the header
    .to("#headerProgressDot", {left: '70%', duration: 8}, 'takeStrategyContinue+=0.3')
    .to("#headerProgressAnimatedLine", {width: "70%", duration: 6, ease: 'linear'}, 'takeStrategyContinue+=0.5')
    // Update prompt cards - removing option 1
    .fromTo("#takeStrategyOption2",{opacity: 1, y: 0}, {opacity: 0, y: 100, display: "none", duration: 0.6}, 'takeStrategyContinue')
    .to("#takeStrategyPromptDottedLine2", {opacity: 0}, 'takeStrategyContinue')
    .to("#autoX__strategyActionsCardText-1-1", {opacity: 0, display: "none", position: "absolute", duration: 0.1}, 'takeStrategyContinue')
    .to("#autoX__strategyActionsCardText-1-3", {opacity: 1, display: "block", duration: 0.4}, 'takeStrategyContinue')
    .call(() => {
        const cardTitle = document.querySelector("#takeStrategyOption1Subtitle");
        if(cardTitle.innerHTML === "Option 1") {
            cardTitle.innerHTML = "Option 2";
        } else {
            cardTitle.innerHTML = "Option 1";
        }
    }, null, 'takeStrategyContinue')
    // Transition status text
    .call(() => {
        transitionStatusText(takeStrategyTimeline,"#autoX__takeStrategyStatusText3", "#autoX__takeStrategyStatusText4", 'takeStrategyContinue', 'takeStrategyContinue+=0.6');
    }, null, 'takeStrategyContinue')
    // Yellow waiting dots dashed line loading animations
    .to("#waiting_dot_1a_outline", {opacity: 1, duration: 0.1}, 'takeStrategyContinue+=1')
    .fromTo("#waiting_dot_1a_dotted_outline_mask_path", {
        drawSVG: "0%",
    }, {
        drawSVG: "100%",
        duration: 0.8,
        ease: 'linear'
    }, 'takeStrategyContinue+=1')
    .to("#waiting_dot_1a_dotted_outline_mask", {display: 'none', duration: 0.1},'takeStrategyContinue+=1.7')
    .fromTo("#waiting_dot_1a_outline", {scale: 1.3, transformOrigin: "center"}, {scale: 1},'takeStrategyContinue+=1.8')
    // Status Orb dashed line loading animation
    .to("#dotted-line-start", {opacity: 1, duration: 0.1}, 'takeStrategyContinue+=1')
    .fromTo("#dotted-line-start-mask-path", {
        drawSVG: "0%",
    }, {
        drawSVG: "100%",
        duration: 0.8,
        ease: 'linear'
    }, 'takeStrategyContinue+=1')
    .to("#dotted-line-start-mask", {display: 'none', duration: 0.1}, 'takeStrategyContinue+=1.8')
    .fromTo("#x-orb", {scale: 1.4}, {scale: 1.1, duration: 0.8}, 'takeStrategyContinue+=2')
    .fromTo("#dotted-line-start-path", {scale: 1, strokeWidth: 2, transformOrigin: "center"}, {strokeWidth: 4, scale: 0.85}, 'takeStrategyContinue+=2')
    // Yellow dashed loop
    .to("#yellow_loop_dotted_1", {opacity: 1, duration: 0.1}, 'takeStrategyContinue+=2.5')
    .fromTo("#order_path_solid_1", {opacity: 1}, {opacity: 0, duration: 0.8}, 'takeStrategyContinue+=2.5')
    .to("#order_dotted_1", {opacity: 0}, 'takeStrategyContinue+=2.5')
    .fromTo("#yellow_loop_line_dotted_1_mask_path", {
        drawSVG: "0%",
    }, {
        drawSVG: "100%",
        duration: 0.8,
        ease: 'linear'
    }, 'takeStrategyContinue+=2.5')
    .to("#yellow_loop_line_dotted_1_mask", {display: 'none', duration: 0.1})
    // Move nav arrow along yellow dashed loop
    .call(() => {
        moveNavArrow(takeStrategyTimeline, "#nav_arrow_group_1a", "#yellow_loop_line_solid_1", 2, 0.39, 1, 'takeStrategyContinue+=3');
    }, null, 'takeStrategyContinue+=3')
    .call(() => {
        moveNavArrow(takeStrategyTimeline, "#nav_arrow_group_1a", "#yellow_loop_line_solid_1", 2.5, 0.15, 1, 'takeStrategyContinue+=5');
    }, null, 'takeStrategyContinue+=5')
    .call(() => {
        moveNavArrow(takeStrategyTimeline, "#nav_arrow_group_1a", "#yellow_loop_line_solid_1", 1, 0.15, 0.39, 'takeStrategyContinue+=7.5');
    }, null, 'takeStrategyContinue+=7.5')
    // Hide dashed loop and waiting dot, show solid order line
    .to("#yellow_loop_dotted_1", {opacity: 0, duration: 0.6}, 'takeStrategyContinue+=9')
    .to("#waiting_dot_1a", {opacity: 0, duration: 0.6}, 'takeStrategyContinue+=9')
    .to("#waiting_dot_1a_outline", {opacity: 0, duration: 0.6}, 'takeStrategyContinue+=9')
    .to("#order_path_solid_1", {opacity: 1, duration: 0.6}, 'takeStrategyContinue+=9')
    // Reset status orb
    .to("#x-orb", {scale: 1.4, duration: 0.8, transformOrigin: "center"}, 'takeStrategyContinue+=9')
    .to("#dotted-line-start-path", {opacity: 0, duration: 0.6}, 'takeStrategyContinue+=9')
    // Transition status text
    .call(() => {
        transitionStatusText(takeStrategyTimeline,"#autoX__takeStrategyStatusText4", "#autoX__takeStrategyStatusText5", 'takeStrategyContinue+=9', 'takeStrategyContinue+=9.6');
    }, null, 'takeStrategyContinue+=9')
    .fromTo("#takeStrategyOption1", {opacity: 1}, {opacity: 0, display: 'none', duration: 0.6}, 'takeStrategyContinue+=9')
    .fromTo("#takeStrategyOption3", {opacity: 0, y: 100}, {opacity: 1, y: 0, display: 'flex', duration: 0.6}, 'takeStrategyContinue+=9.6')
    // Path one animation
    .call(() => {
        animatedDottedLine(takeStrategyTimeline, "#reattempt_dotted_1a", "#reattempt_line_dotted_1a_mask_path", "0%", "100%", 2, 'takeStrategyContinue+=10');
    }, null, 'takeStrategyContinue+=10')
    .to("#reattempt_line_dotted_1a_mask", {display: 'none', duration: 0.1}, 'takeStrategyContinue+=12')
    .fromTo("#reattempt_dot_1a",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.4}, 'takeStrategyContinue+=11.9')
    // Path two animation
    .call(() => {
        animatedDottedLine(takeStrategyTimeline, "#reattempt_dotted_1b", "#reattempt_line_dotted_1b_mask_path", "0%", "100%", 2, 'takeStrategyContinue+=11');
    }, null, 'takeStrategyContinue+=11')
    .to("#reattempt_line_dotted_1b_mask", {display: 'none', duration: 0.1}, 'takeStrategyContinue+=13')
    .fromTo("#reattempt_dot_1b",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.4}, 'takeStrategyContinue+=12.9')
    // Path three animation
    .call(() => {
        animatedDottedLine(takeStrategyTimeline, "#reattempt_dotted_1c", "#reattempt_line_dotted_1c_mask_path", "0%", "100%", 1, 'takeStrategyContinue+=13');
    }, null, 'takeStrategyContinue+=13')
    .to("#reattempt_line_dotted_1c_mask", {display: 'none', duration: 0.1}, 'takeStrategyContinue+=14')
    .fromTo("#reattempt_dot_1c",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.4}, 'takeStrategyContinue+=13.9')
    .fromTo("#takeStrategyNextButton", {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.6}, 'takeStrategyContinue+=14')

}

function optionTwoAnimation() {
    const optionTwoTimeline = gsap.timeline({});
    pastTimelines.push(optionTwoTimeline);

    // Hide waiting dot and option cards and start end animation
    optionTwoTimeline.add('optionTwoContinue')
    // .to("#waiting_dot_1a", {opacity: 0, duration: 0.6}, 'optionTwoContinue')
    // .to("#takeStrategyOption1", {opacity: 0, display: 'none'}, 'optionTwoContinue')
    .to("#takeStrategyOption2", {opacity: 0, display: 'none'}, 'optionTwoContinue')
    // Remove yellow glow from Status Orb
    .to("#yellow-glow", {opacity: 0, duration: 0.6}, 'optionTwoContinue')
    // Animate the progress bar in the header
    .to("#headerProgressDot", {left: '70%', duration: 8}, 'optionTwoContinue+=0.3')
    .to("#headerProgressAnimatedLine", {width: "70%", duration: 6, ease: 'linear'}, 'optionTwoContinue+=0.5')
    // Update prompt cards - removing option 1
    .to("#takeStrategyPromptDottedLine2", {opacity: 0}, 'optionTwoContinue')
    .to("#autoX__strategyActionsCardText-1-1", {opacity: 0, display: "none", position: "absolute", duration: 0.1}, 'optionTwoContinue')
    .to("#autoX__strategyActionsCardText-1-2", {opacity: 1, display: "block", duration: 0.4}, 'optionTwoContinue')
    // Transition status text
    .call(() => {
        transitionStatusText(optionTwoTimeline,"#autoX__takeStrategyStatusText3", "#autoX__takeStrategyStatusText4", 'optionTwoContinue', 'optionTwoContinue+=0.6');
    }, null, 'optionTwoContinue')
    // Yellow waiting dots dashed line loading animations
    .to("#waiting_dot_1a_outline", {opacity: 1, duration: 0.1}, 'optionTwoContinue+=1')
    .fromTo("#waiting_dot_1a_dotted_outline_mask_path", {
        drawSVG: "0%",
    }, {
        drawSVG: "100%",
        duration: 0.8,
        ease: 'linear'
    }, 'optionTwoContinue+=1')
    .to("#waiting_dot_1a_dotted_outline_mask", {display: 'none', duration: 0.1},'optionTwoContinue+=1.7')
    .fromTo("#waiting_dot_1a_outline", {scale: 1.3, transformOrigin: "center"}, {scale: 1},'optionTwoContinue+=1.8')
    // Status Orb dashed line loading animation
    .to("#dotted-line-start", {opacity: 1, duration: 0.1}, 'optionTwoContinue+=1')
    .fromTo("#dotted-line-start-mask-path", {
        drawSVG: "0%",
    }, {
        drawSVG: "100%",
        duration: 0.8,
        ease: 'linear'
    }, 'optionTwoContinue+=1')
    .to("#dotted-line-start-mask", {display: 'none', duration: 0.1} ,'optionTwoContinue+=1.8')
    .fromTo("#x-orb", {scale: 1.4}, {scale: 1.1, duration: 0.8}, 'optionTwoContinue+=2')
    .fromTo("#dotted-line-start-path", {scale: 1, transformOrigin: "center"}, {scale: 0.85, duration: 0.4}, 'optionTwoContinue+=2')
    // Reset status orb
    .to("#waiting_dot_1a", {opacity: 0, duration: 0.6}, 'optionTwoContinue+=9')
    .to("#waiting_dot_1a_outline", {opacity: 0, duration: 0.6}, 'optionTwoContinue+=9')
    // Transition status text
    .call(() => {
        transitionStatusText(optionTwoTimeline,"#autoX__takeStrategyStatusText4", "#autoX__takeStrategyStatusText5", 'optionTwoContinue+=9', 'optionTwoContinue+=9.6');
    }, null, 'optionTwoContinue+=9')
    .fromTo("#takeStrategyOption1", {opacity: 1}, {opacity: 0, display: 'none', duration: 0.6}, 'optionTwoContinue+=9')
    .fromTo("#takeStrategyOption3", {opacity: 0, y: 100}, {opacity: 1, y: 0, display: 'flex', duration: 0.6}, 'optionTwoContinue+=9.6')
    .to("#x-orb", {scale: 1.4, duration: 0.8, transformOrigin: "center"}, 'optionTwoContinue+=9')
    .to("#dotted-line-start-path", {opacity: 0, duration: 0.6}, 'optionTwoContinue+=9')
    // Path one animation
    .call(() => {
        animatedDottedLine(optionTwoTimeline, "#reattempt_dotted_1a", "#reattempt_line_dotted_1a_mask_path", "0%", "100%", 2, 'optionTwoContinue+=10');
    }, null, 'optionTwoContinue+=10')
    .to("#reattempt_line_dotted_1a_mask", {display: 'none', duration: 0.1}, 'optionTwoContinue+=12')
    .fromTo("#reattempt_dot_1a",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.4}, 'optionTwoContinue+=11.9')
    // Path two animation
    .call(() => {
        animatedDottedLine(optionTwoTimeline, "#reattempt_dotted_1b", "#reattempt_line_dotted_1b_mask_path", "0%", "100%", 2, 'optionTwoContinue+=11');
    }, null, 'optionTwoContinue+=11')
    .to("#reattempt_line_dotted_1b_mask", {display: 'none', duration: 0.1}, 'optionTwoContinue+=13')
    .fromTo("#reattempt_dot_1b",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.4}, 'optionTwoContinue+=12.9')
    // Path three animation
    .call(() => {
        animatedDottedLine(optionTwoTimeline, "#reattempt_dotted_1c", "#reattempt_line_dotted_1c_mask_path", "0%", "100%", 1, 'optionTwoContinue+=13');
    }, null, 'optionTwoContinue+=13')
    .to("#reattempt_line_dotted_1c_mask", {display: 'none', duration: 0.1}, 'optionTwoContinue+=14')
    .fromTo("#reattempt_dot_1c",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.4}, 'optionTwoContinue+=13.9')
    .fromTo("#takeStrategyNextButton", {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.6}, 'optionTwoContinue+=14')
    // .call(() => {
    //     endingAnimation();
    // }, null, 'optionTwoContinue+=14')

}

function endingAnimation() {
    const takeStrategyTimeline = gsap.timeline({});
    pastTimelines.push(takeStrategyTimeline);

    takeStrategyTimeline.add('endingAnimation')
    // Animate progress bar
    .to("#headerProgressDot", {left: '98%', duration: 2.4}, 'endingAnimation+=0.5')
    .to("#headerProgressAnimatedLine", {width: "94%", duration: 2.4, ease: 'linear'}, 'endingAnimation+=0.5')
    .to("#headerProgressDot", {opacity: 0, duration: 0.2}, 'endingAnimation+=2.7')
    .call(() => {
        const progressStep = document.getElementById("autoX__headerProgressStep4");
        if(progressStep.className.includes('autoX__headerProgressStep--active')) {
            progressStep.classList.remove('autoX__headerProgressStep--active');
        } else {
            progressStep.classList.add('autoX__headerProgressStep--active');
        }
    }, null, 'endingAnimation+=2.7')
    // Animate text boxes
    .to("#takeStrategyNextButton", {opacity: 0, x: 100, duration: 0.6}, 'endingAnimation')
    .fromTo("#takeStrategyOption3", {opacity: 1}, {opacity: 0, display: 'none', duration: 0.6}, 'endingAnimation')
    .to("#takeStrategyPromptDottedLine1", {opacity: 0}, 'endingAnimation')
    // Hide the three paths and their related waiting dots
    .to("#reattempt_dotted_1a", {opacity: 0, duration: 0.4}, 'endingAnimation')
    .to("#reattempt_dotted_1b", {opacity: 0, duration: 0.4}, 'endingAnimation')
    .to("#reattempt_dotted_1c", {opacity: 0, duration: 0.4}, 'endingAnimation')
    .to("#reattempt_dot_1a", {opacity: 0, duration: 0.4}, 'endingAnimation')
    .to("#reattempt_dot_1b", {opacity: 0, duration: 0.4}, 'endingAnimation')
    .to("#reattempt_dot_1c", {opacity: 0, duration: 0.4}, 'endingAnimation')
    // Animate the order path finishing and checkmarks along the way
    .fromTo("#order_path_solid_1", {drawSVG: "50.6%"}, {drawSVG: "100%", duration: 2}, 'endingAnimation+=0.5')
    // Move Nav Arrow
    .call(() => {
        moveNavArrow(takeStrategyTimeline, "#nav_arrow_group_1a", "#order_path_solid_1", 2, 0.44, 0.95, 'endingAnimation+=0.5');
    }, null, 'endingAnimation+=0.5')
    .fromTo("#check_mark_1d",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'endingAnimation+=0.5')
    .fromTo("#check_mark_1e",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'endingAnimation+=1.16')
    .fromTo("#check_mark_1f",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'endingAnimation+=1.83')
    .fromTo("#check_mark_1g",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'endingAnimation+=2.5')
    // Remove prompt card in bottom section
    .to("#takeStrategyPrompt1", {opacity: 0, display: 'none', duration: 0.4}, 'endingAnimation+=2')
    // Ending success checkmark animation
    .to("#green-glow", {opacity: 1, duration: 0.6}, 'endingAnimation+=2')
    .fromTo("#success_check_front", {opacity: 0, scale: 0, transformOrigin: "center"}, {opacity: 1, scale: 1.2, duration: 0.4}, 'endingAnimation+=2.5')
    .to("#success_check_front", { scale: 1, duration: 0.2, transformOrigin: "center"}, 'endingAnimation+=2.9')
    .fromTo("#success_check_back", {opacity: 0, scale: 0, transformOrigin: "center"}, {opacity: 1, scale: 1.2, duration: 0.4}, 'endingAnimation+=2.5')
    .to("#success_check_back", { scale: 1, duration: 0.2, transformOrigin: "center"}, 'endingAnimation+=2.9')
    // Transition status text
    .call(() => {
        transitionStatusText(takeStrategyTimeline,"#autoX__takeStrategyStatusText5", "#autoX__takeStrategyStatusText6", 'endingAnimation+=2.5', 'endingAnimation+=3.1');
    }, null, 'endingAnimation+=2.5')
    .fromTo("#takeStrategyOption4", {opacity: 0, y: 100}, {opacity: 1, y: 0, display: "flex", duration: 0.6}, 'endingAnimation+=2.5')

}

function previousAnimationStep() {
    const button = document.querySelector("#autoX__strategyBackNav");
    button.disabled = true;

    if(pastTimelines.length > 0) {

        if(pastTimelines.length === 1) {
            pastTimelines[pastTimelines.length - 1].seek('takeStrategyOrderLines+=8');
        }

        pastTimelines[pastTimelines.length - 1].timeScale(2);
        pastTimelines[pastTimelines.length - 1].reverse();
        const currentTimelineLength = pastTimelines[pastTimelines.length - 1].time() / 2;
        pastTimelines.pop();

        // Disable back button until animation finishes
        setTimeout(() => {
            button.disabled = false;
        }, currentTimelineLength * 1000 );
    } else {
        window.location.href = "/lp/autox/landing";
    }
}

function startSpinnerAnimations(timeline, trigger, offset, stagger) {
    let totalOffset = offset;
    for(let i = 1; i <= 19; i++) {
        totalOffset += stagger;
        timeline.fromTo(`#spinner_${i}`, {scale: 0, opacity: 0, transformOrigin: "center"}, {duration: 0.2, opacity: 1, scale: 1}, trigger + '+=' + totalOffset).fromTo(`#spinner_${i}`, 
            {duration: 0.8, opacity:1, transformOrigin: "center",},
            {duration: 0.8, rotation:"+=360",repeat:-1, ease: 'linear'}
        )
    }
}

function animatedDottedLine(timeline, parentId, maskPathId, start, end, duration, timing) {
    timeline.to(parentId, {opacity: 1}, timing)
    .fromTo(maskPathId, {
        drawSVG: start,
    }, {
        drawSVG: end,
        duration: duration,
        ease: 'linear'
    }, timing)
}

function transitionStatusText(timeline, firstTextElement, secondTextElement, firstElementTiming, secondElementTiming) {
    timeline.fromTo(firstTextElement, {opacity: 1, y:0}, {opacity: 0, y: -60, top: 0, display: "none", duration: 0.6}, firstElementTiming)
    .fromTo(secondTextElement, {opacity: 0, y: 80}, { display: "block", opacity: 1, y: 0, duration: 0.6}, secondElementTiming)
}

function moveNavArrow(timeline, arrowId, pathId, duration, start, end, timing) {
    timeline.to(arrowId, {
        duration: duration,
        ease: 'linear',
        motionPath: {
            path: pathId,
            align: pathId,
            type: "cubic",
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
            start: start,
            end: end
        }
    }, timing);
}
