import React from 'react';
import StatusOrb from './StatusOrb';

const StrategyStatusText = ({strategyType}) => {

    if(strategyType === 'take') {
        return (
            <div className="autoX__strategyBrainMapStatus">
                <StatusOrb/>

                <button id="autoX__strategyActionButton" className="autoX__strategyActionButton">
                    <span>Begin order</span>
                </button>

                <div id="autoX__takeStrategyStatusText1" className="autoX__strategyBrainMapStatusText">
                    <span>Aligning with your objectives</span>
                </div>

                <div id="autoX__takeStrategyStatusText2" className="autoX__strategyBrainMapStatusText">
                    <span>Optimizing your pathway</span>
                </div>    

                <div id="autoX__takeStrategyStatusText3" className="autoX__strategyBrainMapStatusText">
                    <span>Sensitivity based on price/spread tolerance from enhanced modeling capabilities</span>
                </div>

                <div id="autoX__takeStrategyStatusText4" className="autoX__strategyBrainMapStatusText">
                    <span>Waiting for market to stabilize</span>
                </div>

                <div id="autoX__takeStrategyStatusText5" className="autoX__strategyBrainMapStatusText">
                    <span>Aligning with your strategies</span>
                </div>

                <div id="autoX__takeStrategyStatusText6" className="autoX__strategyBrainMapStatusText">
                    <span>Order is successfully executed with Adaptive Auto-X!</span>
                </div>

            </div>
        )
    }

    if(strategyType === 'seek') {
        return (
            <div className="autoX__strategyBrainMapStatus">
                <StatusOrb/>

                <button id="autoX__strategyActionButton" className="autoX__strategyActionButton">
                    <span>Begin order</span>
                </button>

                <div id="autoX__takeStrategyStatusText1" className="autoX__strategyBrainMapStatusText">
                    <span>Aligning with your objectives</span>
                </div>

                <div id="autoX__takeStrategyStatusText2" className="autoX__strategyBrainMapStatusText">
                    <span>Optimizing your pathway</span>
                </div>    

                <div id="autoX__takeStrategyStatusText3" className="autoX__strategyBrainMapStatusText">
                    <span>If passive opportunities don’t arise, update your approach</span>
                </div>

                <div id="autoX__takeStrategyStatusText4" className="autoX__strategyBrainMapStatusText">
                    <span>Passively participate via responding to RFQ and resting in Live Markets</span>
                </div>

                <div id="autoX__takeStrategyStatusText5" className="autoX__strategyBrainMapStatusText">
                    <span>Aggress via RFQ (disclosed or anon) and Live Markets order book</span>
                </div>

                <div id="autoX__takeStrategyStatusText6" className="autoX__strategyBrainMapStatusText">
                    <span>Aligning with your updated order instructions</span>
                </div>

                <div id="autoX__takeStrategyStatusText7" className="autoX__strategyBrainMapStatusText">
                    <span>Order is successfully executed with Adaptive Auto-X!</span>
                </div>

            </div>
        )
    }

    
    if(strategyType === 'provide') {
        return (
            <div className="autoX__strategyBrainMapStatus">
                <StatusOrb/>

                <button id="autoX__strategyActionButton" className="autoX__strategyActionButton">
                    <span>Begin order</span>
                </button>

                <div id="autoX__takeStrategyStatusText1" className="autoX__strategyBrainMapStatusText">
                    <span>Aligning with your objectives</span>
                </div>

                <div id="autoX__takeStrategyStatusText2" className="autoX__strategyBrainMapStatusText">
                    <span>Optimizing your pathway</span>
                </div>    

                <div id="autoX__takeStrategyStatusText3" className="autoX__strategyBrainMapStatusText">
                    <span>The Fed signals a hawkish move</span>
                </div>

                <div id="autoX__takeStrategyStatusText4" className="autoX__strategyBrainMapStatusText">
                    <span>Waiting for market to stabilize</span>
                </div>

                <div id="autoX__takeStrategyStatusText5" className="autoX__strategyBrainMapStatusText">
                    <span>Conditions have stabilized</span>
                </div>

                <div id="autoX__takeStrategyStatusText6" className="autoX__strategyBrainMapStatusText">
                    <span>Optimizing your pathway</span>
                </div>

                <div id="autoX__takeStrategyStatusText7" className="autoX__strategyBrainMapStatusText">
                    <span>There’s more competition than expected</span>
                </div>

                <div id="autoX__takeStrategyStatusText8" className="autoX__strategyBrainMapStatusText">
                    <span>Update your price on the fly, no need to step out of the market</span>
                </div>

                <div id="autoX__takeStrategyStatusText9" className="autoX__strategyBrainMapStatusText">
                    <span>Order is successfully executed with Adaptive Auto-X!</span>
                </div>

            </div>
        )
    }
}

export default StrategyStatusText;
