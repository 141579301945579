/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-constructor */
/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unescaped-entities */
import React, {useRef, useEffect, useState, Suspense} from 'react';
import { useParams } from "react-router-dom";
import determineContent from '../helpers/StrategyContent';
import BrainMap from '../components/BrainMap';
import StrategyStatusText from '../components/StrategyStatusText';
import StrategyActionCards from '../components/StrategyActionCards';
import { animateTakeStrategyBrainMap } from '../animations/strategy/TakeStrategyAnimationTimeline';
import { animateSeekStrategyBrainMap } from '../animations/strategy/SeekStrategyAnimationTimeline';
import { animateProvideStrategyBrainMap } from '../animations/strategy/ProvideStrategyAnimationTimeline';



const toggleInfoDropdown = (e) => {
    // Close any open dropdowns
    const dropdowns = document.querySelectorAll('.autoX__strategyInfoDropdown');
    dropdowns.forEach((dropdown) => {
        if(dropdown !== e.target.parentElement) {
            dropdown.classList.remove('autoX__strategyInfoDropdown--open');
        }
    });

    e.target.parentElement.classList.toggle('autoX__strategyInfoDropdown--open');
    setTimeout(() => {
        e.target.parentElement.scrollIntoView({behavior: 'smooth'});
    }, 200);
}

const StrategyPage = () => {
    const { strategyType } = useParams();
    const { title, tags, subtitle, body, dropdowns } = determineContent(strategyType);

    useEffect(() => {
        // Initialize gsap animations
        if(strategyType === 'take') {
            animateTakeStrategyBrainMap();
        } else if(strategyType === 'seek') {
            animateSeekStrategyBrainMap();
        } else if(strategyType === 'provide') {
            animateProvideStrategyBrainMap();
        }
    }, []);

    return (
        <div className="autoX__strategyPage">

            <div className="autoX__strategySidebar">
                <h2>{title}</h2>
                <div className="autoX__strategyTags">
                    {tags.map((tag, index) => {

                        let tagClass = 'autoX__strategyTag__urgency';
                        if(tag.includes('LIQUIDITY')) {
                            tagClass = 'autoX__strategyTag__liquidity';
                        }

                        return (
                            <span class={tagClass} key={index}>{tag}</span>
                        )
                    }
                    )}
                </div>
                <h3>{subtitle}</h3>
                <p className="autoX__strategySidebarBody">{body}</p>

                <div className="autoX__strategyInfoDropdowns">
                    {Object.keys(dropdowns).map((key, index) => (
                        <div key={index} onClick={(e) => toggleInfoDropdown(e)} className="autoX__strategyInfoDropdown">
                            <h4>{key}<span className="fa-solid fa-chevron-down"/></h4>
                            <p className="autoX__strategyInfoDropdownContent">{dropdowns[key]}</p>
                            <span className="fa-solid fa-chevron-up" id="autoX__infoDropdownIcon"/>
                        </div>
                    ))}
                </div>

                <button className="autoX__strategyBackNav" id="autoX__strategyBackNav">
                    <div className="autoX__strategyBackNavIcon">
                        <span className="fa-solid fa-arrow-left-long"/>
                    </div>
                    <span className="autoX__strategyBackLabel">Back</span>
                </button>
            </div>

            <div className="autoX__strategyContent">
                <div className="autoX__strategyBrainMap">
                    <StrategyStatusText strategyType={strategyType}/>

                    <BrainMap/>
                </div>
                
                <StrategyActionCards strategyType={strategyType}/>
            </div>

        </div>
    );
    
}

export default StrategyPage;
