/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import React from 'react';

const BrainMap = () => {

    return (
        <div className="brainMap">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 940 505" enableBackground="new 0 0 940 505" xmlSpace="preserve">
            <g id="brain_base">
                <path id="brain_base_line" fill="none" stroke="#394E62" strokeWidth="4" d="M829.7,244.7H940 M829.7,244.7v-83.8l-64.8-64.8
                    h-96.1l-79.6,79.6H386.6l-68.8,68.8H113.2 M764.8,96.1l-62.5-62.5H380.5L260.9,153.1v91.3 M707.5,96.1L676,64.5H552.8l-53.4,53.4
                    H387.3L260.9,244.4 M409.1,117.9l84.3-84.3 M342.3,162.9l-45.6-45.6 M260.9,244.4v105.2l103.8,103.8h119.6l67.4-67.4h113.4
                    l61.2-61.2h50.4l52.9-52.9v-27.2 M280.5,244.4l84.5,84.5h119.6l85.9-85.9h107.8l67.9-67.9h83.4 M467.1,175.3L339.2,303.1
                    M786.5,175.2l-40.7-40.7H630.3 M607.3,157.5l78.3,78.3 M423.9,218.5l85.6,85.6 M284.8,373.6h100.8l44.7-44.7 M535.6,277.9h223.8
                    l70.3-70.3 M690.1,361.1l-20.7-20.7h-72L551.8,386 M572.5,365.1h-88.2l-88.4,88.4 M414.6,453.5V505 M436.2,453.5v21.1"/>
            </g>
            <g id="_x31_0m_pill">
                <path id="_x31_0m_pill_shape" fill="#FFFFFF" d="M64.8,373.3h98c7.7,0,14,6.3,14,14l0,0c0,7.7-6.3,14-14,14h-98
                    c-7.7,0-14-6.3-14-14l0,0C50.8,379.6,57.1,373.3,64.8,373.3z"/>
                <rect x="50.8" y="373.3" fill="none" width="126" height="28"/>
                <text id="_x31_0m_text" transform="matrix(1 0 0 1 62.7976 394.1581)" fill="#1A5587" fontFamily="'Exo'" fontWeight="700" fontSize="18px">$10M US HG</text>
            </g>
            <g id="sample_order_text">
                <rect x="50.8" y="347.9" fill="none" width="126" height="17.4"/>
                <text id="sample_order" transform="matrix(1 0 0 1 62.0505 361.9236)" fill="#FFFFFF" fontFamily="'Exo'" fontWeight="700" fontSize="14px">SAMPLE ORDER</text>
            </g>
            <g id="spinner_1">
                <foreignObject x="244.9" y="333.7" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_2">
                <foreignObject x="280.8" y="101.3" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_3">
                <foreignObject x="301.8" y="228.4" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_4">
                <foreignObject x="348.7" y="437.5" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_5">
                <foreignObject x="349" y="312.9" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_6">
                <foreignObject x="370.7" y="159.7" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_7">
                <foreignObject x="468.3" y="349.1" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_8">
                <foreignObject x="477.6" y="17.5" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_9">
                <foreignObject x="493.5" y="288.1" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_10">
                <foreignObject x="536.8" y="48.5" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_11">
                <foreignObject x="554.6" y="227" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_12">
                <foreignObject x="573.2" y="159.1" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_13">
                <foreignObject x="581.5" y="324.4" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_14">
                <foreignObject x="652.8" y="80.1" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_15">
                <foreignObject x="669.6" y="219.8" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_16">
                <foreignObject x="710.3" y="308.8" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_17">
                <foreignObject x="764.8" y="96.1" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_18">
                <foreignObject x="813.7" y="144.9" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="spinner_19">
                <foreignObject x="813.7" y="228.7" width="32" height="32">
                    <div className="gradient" xmlns="http://www.w3.org/1999/xhtml" ></div>
                </foreignObject>
            </g>
            <g id="yellow_loop_solid_1">
                
                    <path id="yellow_loop_line_solid_1" fill="none" stroke="#FFDA00" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M113.2,244.4h204.6l68.8-68.8h202.6l18.2-18.2l78.3,78.3l-7.2,7.2H570.6l-85.9,85.9H365l-84.3-84.3"/>
            </g>
            <g id="yellow_loop_dotted_1">
                <g>
                    <polyline mask="url(#yellow_loop_line_dotted_1_mask)" id="yellow_loop_line_dotted_1" fill="none" stroke="#FFDA00" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="280.7,244.7 
                    281.1,245 357.7,321.6 364.7,328.6 365,328.9 365.5,328.9 484.6,328.9 570.6,243 678.2,243 685.2,236.1 685.6,235.8 685.2,235.4 
                    614.8,165 607.7,157.9 607.3,157.5 607,157.9 589.2,175.7 397,175.7 387.1,175.7 386.6,175.7 386.2,176 325.1,237.1 318.2,244.1 
                    317.8,244.4 317.3,244.4 123.5,244.4 113.7,244.4 113.2,244.4"/>
                    <mask id="yellow_loop_line_dotted_1_mask">
                        <polyline id="yellow_loop_line_dotted_1_mask_path" fill="none" stroke="#FFDA00" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="113.2,244.4 
                        113.7,244.4 123.5,244.4 317.3,244.4 317.8,244.4 318.2,244.1 325.1,237.1 386.2,176 386.6,175.7 387.1,175.7 397,175.7 
                        589.2,175.7 607,157.9 607.3,157.5 607.7,157.9 614.8,165 685.2,235.4 685.6,235.8 685.2,236.1 678.2,243 570.6,243 484.6,328.9 
                        365.5,328.9 365,328.9 364.7,328.6 357.7,321.6 281.1,245 280.7,244.7"/>
                    </mask>
                </g>
            </g>
            <g id="reattempt_dotted_2a">
                <g>
                    <polyline mask="url(#reattempt_line_dotted_2a_mask)" id="reattempt_line_dotted_2a" fill="none" stroke="#FFDA00" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="339.6,302.8 
                    339.2,303.1 339.6,303.5 365,328.9 364.7,328.6 365,328.9 365.5,328.9 484.6,328.9 570.6,243 678.2,243 685.2,236.1 685.6,235.8 
                    685.2,235.4 607.3,157.5 607.7,157.9 607.3,157.5 607,157.9 589.2,175.7 466.7,175.7 346.6,295.7"/>
                    <mask id="reattempt_line_dotted_2a_mask">
                        <polyline id="reattempt_line_dotted_2a_mask_path" fill="none" stroke="#FFDA00" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="346.6,295.7
                        466.7,175.7 589.2,175.7 607,157.9 607.3,157.5 607.7,157.9 607.3,157.5 685.2,235.4 685.6,235.8 685.2,236.1 678.2,243 570.6,243
                        484.6,328.9 365.5,328.9 365,328.9 364.7,328.6 365,328.9 339.6,303.5 339.2,303.1 339.6,302.8"/>
                    </mask>
                </g>
            </g>
            <g>

                <polyline id="yellow_loop_line_dotted_1_arrow_path" fill="none" stroke="#FFDA00" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="678.2,243 
                    570.6,243 484.6,328.9 365.5,328.9 365,328.9 364.7,328.6 365,328.9 281.1,245 280.7,244.7 280.5,244.4 317.3,244.4 317.8,244.4 
                    318.2,244.1 325.1,237.1 386.2,176 386.6,175.7 387.1,175.7 386.6,175.7 588.3,175.7 607,157.9 607.3,157.5 607.7,157.9 
                    607.3,157.5 685.2,235.4 685.6,235.8 685.2,236.1 			"/>
                
            </g>
            <g id="order_solid_1">
                <path id="order_path_solid_1" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M113.2,244.4h204.6l68.8-68.8h202.6l18.2-18.2l78.3,78.3l60.7-60.7h83.4v69.6H940"/>
            </g>
            <g id="order_dotted_1">
                <g>
                    <polyline mask="url(#order_path_dotted_1_mask)" id="order_path_dotted_1" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1.0381,10.3808,0,0,0,0" points="940,244.7 
                    939.5,244.7 840.2,244.7 830.2,244.7 829.7,244.7 829.7,244.2 829.7,186.2 829.7,175.6 829.7,175.1 829.2,175.1 756.2,175.1 
                    746.7,175.1 746.2,175.1 745.9,175.5 692.8,228.5 685.9,235.4 685.6,235.8 685.2,235.4 614.8,165 607.7,157.9 607.3,157.5 
                    607,157.9 589.2,175.7 397,175.7 387.1,175.7 386.6,175.7 386.2,176 325.1,237.1 318.2,244.1 317.8,244.4 317.3,244.4 
                    123.5,244.4 113.7,244.4 113.2,244.4 "/>
                    <mask id="order_path_dotted_1_mask" maskUnits="userSpaceOnUse">
                        <polyline id="order_path_dotted_1_mask_path" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1.0381,10.3808,0,0,0,0" points="113.2,244.4 
                        113.7,244.4 123.5,244.4 317.3,244.4 317.8,244.4 318.2,244.1 325.1,237.1 386.2,176 386.6,175.7 387.1,175.7 397,175.7 
                        589.2,175.7 607,157.9 607.3,157.5 607.7,157.9 614.8,165 685.2,235.4 685.6,235.8 685.9,235.4 692.8,228.5 745.9,175.5 
                        746.2,175.1 746.7,175.1 756.2,175.1 829.2,175.1 829.7,175.1 829.7,175.6 829.7,186.2 829.7,244.2 829.7,244.7 830.2,244.7 
                        840.2,244.7 939.5,244.7 940,244.7 "/>
                    </mask>
                </g>
            </g>
            <g id="order_solid_2a">
                
                    <polyline id="order_path_solid_2a" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    260.9,244.4 260.9,153.1 296.8,117.3 342.3,162.9 387.3,117.9 409.1,117.9 493.4,33.5 702.3,33.5 829.7,160.9 829.7,244.7 	"/>
            </g>
            <g id="order_dotted_2a">
                <g>
                    <polyline mask="url(#order_path_dotted_2a_mask)" id="order_path_dotted_2a" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="829.7,175.2 
                    829.7,174.7 829.7,160.9 709.6,40.8 702.7,33.9 702.3,33.5 701.8,33.5 503.9,33.5 493.9,33.5 493.4,33.5 493.1,33.9 409.1,117.9 
                    387.3,117.9 349.6,155.6 342.7,162.5 342.3,162.9 342,162.5 304,124.5 297.1,117.6 296.8,117.3 296.4,117.6 267.8,146.3 
                    261.3,152.8 260.9,153.1 260.9,153.6 260.9,233.6 260.9,243.9 260.9,244.4"/>
                    <mask id="order_path_dotted_2a_mask" maskUnits="userSpaceOnUse">
                        <polyline id="order_path_dotted_2a_mask_path" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="260.9,244.4 
                        260.9,243.9 260.9,233.6 260.9,153.6 260.9,153.1 261.3,152.8 267.8,146.3 296.4,117.6 296.8,117.3 297.1,117.6 304,124.5 
                        342,162.5 342.3,162.9 342.7,162.5 349.6,155.6 387.3,117.9 409.1,117.9 493.1,33.9 493.4,33.5 493.9,33.5 503.9,33.5 
                        701.8,33.5 702.3,33.5 702.7,33.9 709.6,40.8 829.7,160.9 829.7,174.7 829.7,175.2"/>
                    </mask>
                </g>
            </g>
            <g id="order_solid_2b">
                
                    <polyline id="order_path_solid_2b" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    113.2,244.4 280.5,244.4 339.2,303.1 466.7,175.7 589.2,175.7 630.3,134.5 745.8,134.5 786.5,175.2 829.7,175.2 829.7,244.7 
                    940,244.7 	"/>
            </g>
            <g id="order_dotted_2b">
                <g>
                    <polyline mask="url(#order_path_dotted_2b_mask)" id="order_path_dotted_2b" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="940,244.7 
                    939.5,244.7 840.2,244.7 830.2,244.7 829.7,244.7 829.7,244.2 829.7,186.3 829.7,175.7 829.7,175.2 829.2,175.2 786.5,175.2 
                    745.8,134.5 630.3,134.5 589.2,175.7 477.2,175.7 467.2,175.7 466.7,175.7 466.3,176 346.8,295.5 339.6,302.8 339.2,303.1 
                    338.9,302.8 280.5,244.4 123.6,244.4 113.7,244.4 113.2,244.4"/>

                    <mask id="order_path_dotted_2b_mask">
                        <polyline id="order_path_dotted_2b_mask_path" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="113.2,244.4 
                        113.7,244.4 123.6,244.4 280.5,244.4 338.9,302.8 339.2,303.1 339.6,302.8 346.8,295.5 466.3,176 466.7,175.7 467.2,175.7 
                        477.2,175.7 589.2,175.7 630.3,134.5 745.8,134.5 786.5,175.2 829.2,175.2 829.7,175.2 829.7,175.7 829.7,186.3 829.7,244.2 
                        829.7,244.7 830.2,244.7 840.2,244.7 939.5,244.7 940,244.7"/>
                    </mask>
                </g>
            </g>
            <g id="order_solid_2c">
                
                    <polyline id="order_path_solid_2c" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    260.9,244.4 260.9,349.7 364.7,453.5 395.9,453.5 484.3,365.1 572.5,365.1 597.3,340.3 669.4,340.4 690.1,361 726.3,324.8 
                    776.7,324.8 829.7,271.9 829.7,244.7 	"/>
            </g>
            <g id="order_dotted_2c">
                <g>
                    <polyline mask="url(#order_path_dotted_2c_mask)" id="order_path_dotted_2c" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="829.7,244.7 
                    829.7,245.2 829.7,271.9 776.7,324.8 726.3,324.8 697.4,353.8 690.5,360.7 690.1,361 689.8,360.7 669.4,340.4 608.1,340.3 
                    597.8,340.3 597.3,340.3 597,340.7 572.5,365.1 495,365.1 484.8,365.1 484.3,365.1 484,365.4 403.6,445.8 396.3,453.1 
                    395.9,453.5 395.4,453.5 364.7,453.5 268.4,357.2 261.3,350 260.9,349.7 260.9,349.2 260.9,254.5 260.9,244.9 260.9,244.4"/>
                    <mask id="order_path_dotted_2c_mask">
                        <polyline id="order_path_dotted_2c_mask_path" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="260.9,244.4
                        260.9,244.9 260.9,254.5 260.9,349.2 260.9,349.7 261.3,350 268.4,357.2 364.7,453.5 395.4,453.5 395.9,453.5 396.3,453.1
                        403.6,445.8 484,365.4 484.3,365.1 484.8,365.1 495,365.1 572.5,365.1 597,340.7 597.3,340.3 597.8,340.3 608.1,340.3
                        669.4,340.4 689.8,360.7 690.1,361 690.5,360.7 697.4,353.8 726.3,324.8 776.7,324.8 829.7,271.9 829.7,245.2 829.7,244.7"/>
                    </mask>
                </g>
            </g>
            <g id="order_solid_3a">
                    <polyline id="order_path_solid_3a" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    260.9,244.4 260.9,153.1 380.5,33.5 493.4,33.5 702.3,33.5 829.7,160.9 829.7,244.7 	"/>
            </g>
            <g id="order_dotted_3a">
                
                    <polyline mask="url(#order_path_dotted_3a_mask)" id="order_path_dotted_3a" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeDasharray="1,10" points="
                    260.9,244.4 260.9,153.1 380.5,33.5 493.4,33.5 702.3,33.5 829.7,160.9 829.7,244.7 	"/>
                    <mask id="order_path_dotted_3a_mask">
                        <polyline id="order_path_dotted_3a_mask_path" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeDasharray="1,10" points="
                        260.9,244.4 260.9,153.1 380.5,33.5 493.4,33.5 702.3,33.5 829.7,160.9 829.7,244.7 	"/>
                    </mask>
            </g>
            <g id="order_solid_3b">
                
                    <polyline id="order_path_solid_3b" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    113.2,244.4 317.8,244.4 386.6,175.7 589.2,175.7 607.3,157.5 685.6,235.8 746.2,175.1 829.7,175.1 829.7,244.7 940,244.7 	"/>
            </g>
            <g id="order_solid_3b_end">
                <line id="order_path_solid_3b_end" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="829.7" y1="244.7" x2="940" y2="244.7"/>
            </g>

            <g id="order_dotted_3b">
                
                    <polyline mask="url(#order_path_dotted_3b_mask)" id="order_path_dotted_3b" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeDasharray="1,10" points="
                    113.2,244.4 317.8,244.4 386.6,175.7 589.2,175.7 607.3,157.5 685.6,235.8 746.2,175.1 829.7,175.1 829.7,244.7 940,244.7 	"/>
                    <mask id="order_path_dotted_3b_mask">
                        <polyline id="order_path_dotted_3b_mask_path" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeDasharray="1,10" points="
                        113.2,244.4 317.8,244.4 386.6,175.7 589.2,175.7 607.3,157.5 685.6,235.8 746.2,175.1 829.7,175.1 829.7,244.7 940,244.7 	"/>
                    </mask>
            </g>
            <g id="order_solid_3c">
                
                    <polyline id="order_path_solid_3c" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    260.9,244.4 260.9,349.7 284.8,373.6 385.7,373.6 430.4,328.9 484.6,328.9 535.6,277.9 759.4,277.9 829.7,207.6 	"/>
            </g>
            <g id="order_dotted_3c">
                
                    <polyline mask="url(#order_path_dotted_3c_mask)" id="order_path_dotted_3c" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeDasharray="1,10" points="
                    260.9,244.4 260.9,349.7 284.8,373.6 385.7,373.6 430.4,328.9 484.6,328.9 535.6,277.9 759.4,277.9 829.7,207.6 	"/>
                    <mask id="order_path_dotted_3c_mask">
                        <polyline id="order_path_dotted_3c_mask_path" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeDasharray="1,10" points="
                        260.9,244.4 260.9,349.7 284.8,373.6 385.7,373.6 430.4,328.9 484.6,328.9 535.6,277.9 759.4,277.9 829.7,207.6 	"/>
                    </mask>
            </g>
            <g id="reattempt_dotted_1a">
                <g>
                    <polyline mask="url(#reattempt_line_dotted_1a_mask)" id="reattempt_line_dotted_1a" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="707.5,96 
                    707,96 668.6,96.2 607.3,157.5 589.2,175.7 477.5,175.7 467.6,175.7 467.1,175.7"/>
                    <mask id="reattempt_line_dotted_1a_mask">
                        <polyline id="reattempt_line_dotted_1a_mask_path" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="467.1,175.7 
                        467.6,175.7 477.5,175.7 589.2,175.7 607.3,157.5 668.6,96.2 707,96 707.5,96"/>
                    </mask>
                </g>
            </g>
            <g id="reattempt_solid_1a">
                
                    <path id="reattempt_line_solid_1a" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M467.1,175.7h122.1l18.2-18.2l61.3-61.3l38.9-0.2"/>
            </g>
            <g id="reattempt_dot_1a">
                <circle id="yellow_reattempt_dot_1a" fill="#FFDA00" cx="707.5" cy="96.1" r="10.5"/>
            </g>
            <g id="reattempt_dotted_1b">
                <g>
                    <polyline mask="url(#reattempt_line_dotted_1b_mask)" id="reattempt_line_dotted_1b" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="745.8,134.5 
                    745.3,134.5 640.3,134.5 630.8,134.5 630.3,134.5"/>
                    <mask id="reattempt_line_dotted_1b_mask">
                        <polyline id="reattempt_line_dotted_1b_mask_path" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="630.3,134.5 
                        630.8,134.5 640.3,134.5 745.3,134.5 745.8,134.5"/>
                    </mask>
                </g>
            </g>
            <g id="reattempt_solid_1b">
                
                    <path id="reattempt_line_solid_1b" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M630.3,134.5h115.5"/>
            </g>
            <g id="reattempt_dot_1b">
                <circle id="yellow_reattempt_dot_1b" fill="#FFDA00" cx="745.8" cy="134.5" r="10.5"/>
            </g>
            <g id="reattempt_dotted_1c">
                <g>
                    <polyline mask="url(#reattempt_line_dotted_1c_mask)" id="reattempt_line_dotted_1c" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="685.6,235.8 
                    685.2,235.4 614.8,165 607.7,157.9 607.3,157.5"/>
                    <mask id="reattempt_line_dotted_1c_mask">
                        <polyline id="reattempt_line_dotted_1c_mask_path" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="607.3,157.5 
                        607.7,157.9 614.8,165 685.2,235.4 685.6,235.8"/>
                    </mask>
                </g>
            </g>
            <g id="reattempt_solid_1c">
                
                    <path id="reattempt_line_solid_1c" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M607.3,157.5l78.3,78.3"/>
            </g>
            <g id="reattempt_dot_1c">
                <circle id="yellow_reattempt_dot_1c" fill="#FFDA00" cx="685.6" cy="235.8" r="10.5"/>
            </g>
            <g id="reattempt_solid_2a">
                
                    <path id="reattempt_line_solid_2a" fill="none" stroke="#FFDA00" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M339.2,303.1l127.5-127.5h122.5l18.2-18.2l78.3,78.3l-7.2,7.2H570.6l-85.9,85.9H365L339.2,303.1"/>
            </g>
            <g id="reattempt_solid_3a">
                
                    <path id="reattempt_line_solid_3a" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M607.3,157.5l78.3,78.3l60.7-60.7"/>
            </g>
            <g id="reattempt_dotted_3a">
                <g id="reattempt_line_dotted_3a">
                    <g>
                        
                            <line fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" x1="607.3" y1="157.5" x2="607.7" y2="157.9"/>
                        
                            <line fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1.0062,10.0616,0,0,0,0" x1="614.8" y1="165" x2="685.2" y2="235.4"/>
                        <polyline fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" points="685.2,235.4 
                            685.6,235.8 685.9,235.4 			"/>
                        
                            <line fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.9744,9.7444,0,0,0,0" x1="692.8" y1="228.5" x2="745.9" y2="175.5"/>
                        
                            <line fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" x1="745.9" y1="175.5" x2="746.2" y2="175.1"/>
                    </g>
                </g>
            </g>
            <g id="reattempt_solid_3b">
                
                    <polyline id="reattempt_line_solid_3b" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    589.2,175.7 668.8,96.1 764.8,96.1 	"/>
            </g>
            <g id="reattempt_dotted_3b">
                <g id="reattempt_line_dotted_3b">
                    <g>
                        
                            <line fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" x1="589.2" y1="175.7" x2="589.5" y2="175.3"/>
                        
                            <polyline fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.9983,9.9828,0,0,0,0" points="
                            596.6,168.3 668.8,96.1 764.3,96.1 			"/>
                        
                            <line fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" x1="764.3" y1="96.1" x2="764.8" y2="96.1"/>
                    </g>
                </g>
            </g>
            <g id="check_mark_1a">
                <circle id="check_circle_1a" fill="#E9ECF0" cx="260.9" cy="244.4" r="14.4"/>
                
                    <polyline id="check_1a" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    253.2,245.9 257.4,250.1 268.7,238.8 	"/>
            </g>
            <g id="check_mark_1b">
                <circle id="check_circle_1b" fill="#E9ECF0" cx="386.6" cy="175.7" r="14.4"/>
                
                    <polyline id="check_1b" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    378.8,177.1 383.1,181.3 394.4,170 	"/>
            </g>
            <g id="check_mark_1c">
                <circle id="check_circle_1c" fill="#E9ECF0" cx="467.1" cy="175.7" r="14.4"/>
                
                    <polyline id="check_1c" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    459.3,177.1 463.5,181.3 474.8,170 	"/>
            </g>
            <g id="check_mark_1d">
                <circle id="check_circle_1d" fill="#E9ECF0" cx="589.2" cy="175.7" r="14.4"/>
                
                    <polyline id="check_1d" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    581.4,177.1 585.6,181.3 596.9,170 	"/>
            </g>
            <g id="check_mark_1e">
                <circle id="check_circle_1e" fill="#E9ECF0" cx="685.6" cy="235.8" r="14.4"/>
                
                    <polyline id="check_1e" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    677.8,237.2 682,241.4 693.4,230.1 	"/>
            </g>
            <g id="check_mark_1f">
                <circle id="check_circle_1f" fill="#E9ECF0" cx="746.2" cy="175.1" r="14.4"/>
                
                    <polyline id="check_1f" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    738.5,176.5 742.7,180.8 754,169.4 	"/>
            </g>
            <g id="check_mark_1g">
                <circle id="check_circle_1g" fill="#E9ECF0" cx="829.7" cy="244.7" r="14.4"/>
                
                    <polyline id="check_1g" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    821.9,246.1 826.1,250.3 837.4,239 	"/>
            </g>
            <g id="check_mark_2a">
                <circle id="check_circle_2a" fill="#E9ECF0" cx="260.9" cy="153.4" r="14.4"/>
                
                    <polyline id="check_2a" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    253.2,154.8 257.4,159 268.7,147.7 	"/>
            </g>
            <g id="check_mark_2b">
                <circle id="check_circle_2b" fill="#E9ECF0" cx="387.6" cy="117.9" r="14.4"/>
                
                    <polyline id="check_2b" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    379.9,119.3 384.1,123.5 395.4,112.2 	"/>
            </g>
            <g id="check_mark_2c">
                <circle id="check_circle_2c" fill="#E9ECF0" cx="493.4" cy="33.5" r="14.4"/>
                
                    <polyline id="check_2c" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    485.7,35 489.9,39.2 501.2,27.9 	"/>
            </g>
            <g id="check_mark_2d">
                <circle id="check_circle_2d" fill="#E9ECF0" cx="702.2" cy="33.5" r="14.4"/>
                
                    <polyline id="check_2d" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    694.5,35 698.7,39.2 710,27.9 	"/>
            </g>
            <g id="check_mark_2e">
                <circle id="check_circle_2e" fill="#E9ECF0" cx="339.2" cy="303.3" r="14.4"/>
                
                    <polyline id="check_2e" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    331.5,304.7 335.7,308.9 347,297.6 	"/>
            </g>
            <g id="check_mark_2f">
                <circle id="check_circle_2f" fill="#E9ECF0" cx="745.7" cy="134.5" r="14.4"/>
                
                    <polyline id="check_2f" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    738,136 742.2,140.2 753.5,128.9 	"/>
            </g>
            <g id="check_mark_2g">
                <circle id="check_circle_2g" fill="#E9ECF0" cx="284.8" cy="373.6" r="14.4"/>
                
                    <polyline id="check_2g" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    277.1,375.1 281.3,379.3 292.6,368 	"/>
            </g>
            <g id="check_mark_2h">
                <circle id="check_circle_2h" fill="#E9ECF0" cx="395.9" cy="453.5" r="14.4"/>
                
                    <polyline id="check_2h" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    388.2,454.9 392.4,459.1 403.7,447.8 	"/>
            </g>
            <g id="check_mark_2i">
                <circle id="check_circle_2i" fill="#E9ECF0" cx="484.2" cy="365.1" r="14.4"/>
                
                    <polyline id="check_2i" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    476.5,366.5 480.7,370.8 492,359.4 	"/>
            </g>
            <g id="check_mark_2j">
                <circle id="check_circle_2j" fill="#E9ECF0" cx="572.7" cy="365.1" r="14.4"/>
                
                    <polyline id="check_2j" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    565,366.5 569.2,370.8 580.5,359.4 	"/>
            </g>
            <g id="check_mark_2k">
                <circle id="check_circle_2k" fill="#E9ECF0" cx="669.4" cy="340.3" r="14.4"/>
                
                    <polyline id="check_2k" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    661.7,341.8 665.9,346 677.2,334.7 	"/>
            </g>
            <g id="check_mark_2l">
                <circle id="check_circle_2l" fill="#E9ECF0" cx="776.7" cy="324.8" r="14.4"/>
                
                    <polyline id="check_2l" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    768.9,326.3 773.1,330.5 784.5,319.2 	"/>
            </g>
            <g id="check_mark_3a">
                <circle id="check_circle_3a" fill="#E9ECF0" cx="380.5" cy="33.5" r="14.4"/>
                
                    <polyline id="check_3a" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    372.7,35 377,39.2 388.3,27.9 	"/>
            </g>
            <g id="check_mark_3b">
                <circle id="check_circle_3b" fill="#E9ECF0" cx="607.3" cy="157.5" r="14.4"/>
                
                    <polyline id="check_3b" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    599.6,158.9 603.8,163.2 615.1,151.8 	"/>
            </g>
            <g id="check_mark_3c">
                <circle id="check_circle_3c" fill="#E9ECF0" cx="430.4" cy="328.9" r="14.4"/>
                
                    <polyline id="check_3c" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    422.6,330.4 426.8,334.6 438.1,323.3 	"/>
            </g>
            <g id="check_mark_3d">
                <circle id="check_circle_3d" fill="#E9ECF0" cx="535.6" cy="277.9" r="14.4"/>
                
                    <polyline id="check_3d" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    527.9,279.4 532.1,283.6 543.4,272.3 	"/>
            </g>
            <g id="check_mark_3e">
                <circle id="check_circle_3e" fill="#E9ECF0" cx="759.4" cy="277.9" r="14.4"/>
                
                    <polyline id="check_3e" fill="none" stroke="#1E5381" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    751.7,279.4 755.9,283.6 767.2,272.3 	"/>
            </g>
            <g id="waiting_dot_1a">
                <circle id="yellow_waiting_dot_1a" fill="#FFDA00" cx="589.2" cy="175.2" r="15"/>
            </g>
            <g id="waiting_dot_2a">
                <circle id="yellow_waiting_dot_2a" fill="#FFDA00" cx="466.7" cy="175.7" r="10.5"/>
            </g>
            <g id="waiting_dot_3a">
                <circle id="yellow_waiting_dot_3a" fill="#FFDA00" cx="466.7" cy="175.7" r="10.5"/>
            </g>
            <g id="waiting_dot_3b">
                <circle id="yellow_waiting_dot_3b" fill="#FFDA00" cx="607.3" cy="157.5" r="10.5"/>
            </g>
            <g id="waiting_dot_1a_outline">
                <g>
                    <circle mask="url(#waiting_dot_1a_dotted_outline_mask)" id="waiting_dot_1a_dotted_outline" fill="none" stroke="#FFFFFF" strokeWidth="1.8" strokeLinecap="round" strokeMiterlimit="10" strokeDasharray="1.9941,5.4839,0,0,0,0" cx="589.2" cy="175.2" r="25"/>
                    <mask id="waiting_dot_1a_dotted_outline_mask" maskUnits="userSpaceOnUse">
                        <circle id="waiting_dot_1a_dotted_outline_mask_path" fill="none" stroke="#FFFFFF" strokeWidth="1.8" strokeLinecap="round" strokeMiterlimit="10" strokeDasharray="1.9941,5.4839,0,0,0,0" cx="589.2" cy="175.2" r="25"/>
                    </mask>
                </g>
            </g>
            <g id="waiting_dot_3a_outline">
                <g>
                    <circle mask="url(#waiting_dot_3a_dotted_outline_mask)" id="waiting_dot_3a_dotted_outline" fill="none" stroke="#FFFFFF" strokeWidth="1.8" strokeLinecap="round" strokeMiterlimit="10" strokeDasharray="1.9941,5.4839,0,0,0,0" cx="466.7" cy="175.2" r="25"/>
                    <mask id="waiting_dot_3a_dotted_outline_mask" maskUnits="userSpaceOnUse">
                        <circle id="waiting_dot_3a_dotted_outline_mask_path" fill="none" stroke="#FFFFFF" strokeWidth="1.8" strokeLinecap="round" strokeMiterlimit="10" strokeDasharray="1.9941,5.4839,0,0,0,0" cx="466.7" cy="175.2" r="25"/>
                    </mask>
                </g>
            </g>
            <g id="nav_arrow_group_1a">
                
                    <linearGradient id="nav_circle_fill_1a_00000165207561894152832050000013167247683306642312_" gradientUnits="userSpaceOnUse" x1="511.0678" y1="177.9644" x2="497.2119" y2="158.1044">
                    <stop  offset="0" style={{stopColor: "#000000"}}/>
                    <stop  offset="1" style={{stopColor: "#1E5381"}}/>
                </linearGradient>
                
                    <circle id="nav_circle_fill_1a" opacity="0.7" fill="url(#nav_circle_fill_1a_00000165207561894152832050000013167247683306642312_)" cx="509.5" cy="175.7" r="22.6"/>
                
                    <circle id="nav_circle_outline_1a" fill="none" stroke="#E9ECF0" strokeWidth="1.5714" strokeLinecap="round" strokeMiterlimit="10" cx="509.5" cy="175.7" r="22.6"/>
                <polygon id="nav_arrow_1a" fill="#E9ECF0" points="500.5,187.7 505.7,175.7 500.5,163.7 524.5,175.7 	"/>
            </g>
            <g id="nav_arrow_group_3a">
                
                    <linearGradient id="nav_circle_fill_3a_00000166661325652123455700000017126678208706100151_" gradientUnits="userSpaceOnUse" x1="422.2022" y1="177.9644" x2="408.3463" y2="158.1044">
                    <stop  offset="0" style={{stopColor: "#000000"}}/>
                    <stop  offset="1" style={{stopColor: "#1E5381"}}/>
                </linearGradient>
                
                    <circle id="nav_circle_fill_3a" opacity="0.7" fill="url(#nav_circle_fill_3a_00000166661325652123455700000017126678208706100151_)" cx="420.6" cy="175.7" r="22.6"/>
                
                    <circle id="nav_circle_outline_3a" fill="none" stroke="#E9ECF0" strokeWidth="1.5714" strokeLinecap="round" strokeMiterlimit="10" cx="420.6" cy="175.7" r="22.6"/>
                <polygon id="nav_arrow_3a" fill="#E9ECF0" points="411.7,187.7 416.9,175.7 411.7,163.7 435.7,175.7 	"/>
            </g>
            <g id="nav_arrow_group_2c">
                
                    <linearGradient id="nav_circle_fill_2c_00000132073528439041245140000000078476583488610693_" gradientUnits="userSpaceOnUse" x1="530.7246" y1="367.3974" x2="516.8688" y2="347.5374">
                    <stop  offset="0" style={{stopColor: "#000000"}}/>
                    <stop  offset="1" style={{stopColor: "#1E5381"}}/>
                </linearGradient>
                
                    <circle id="nav_circle_fill_2c" opacity="0.7" fill="url(#nav_circle_fill_2c_00000132073528439041245140000000078476583488610693_)" cx="529.1" cy="365.1" r="22.6"/>
                
                    <circle id="nav_circle_outline_2c" fill="none" stroke="#E9ECF0" strokeWidth="1.5714" strokeLinecap="round" strokeMiterlimit="10" cx="529.1" cy="365.1" r="22.6"/>
                <polygon id="nav_arrow_2c" fill="#E9ECF0" points="520.2,377.1 525.4,365.1 520.2,353.1 544.2,365.1 	"/>
            </g>
            <g id="nav_arrow_group_2b">
                
                    <linearGradient id="nav_circle_fill_2b_00000121969431934821639780000006101780762116323735_" gradientUnits="userSpaceOnUse" x1="562.2022" y1="177.9644" x2="548.3464" y2="158.1044">
                    <stop  offset="0" style={{stopColor: "#000000"}}/>
                    <stop  offset="1" style={{stopColor: "#1E5381"}}/>
                </linearGradient>
                
                    <circle id="nav_circle_fill_2b" opacity="0.7" fill="url(#nav_circle_fill_2b_00000121969431934821639780000006101780762116323735_)" cx="560.6" cy="175.7" r="22.6"/>
                
                    <circle id="nav_circle_outline_2b" fill="none" stroke="#E9ECF0" strokeWidth="1.5714" strokeLinecap="round" strokeMiterlimit="10" cx="560.6" cy="175.7" r="22.6"/>
                <polygon id="nav_arrow_2b" fill="#E9ECF0" points="551.7,187.7 556.9,175.7 551.7,163.7 575.7,175.7 	"/>
            </g>
            <g id="nav_arrow_group_2a">
	
                    <linearGradient id="nav_circle_fill_2a_00000156573815173583123420000006640654611949684411_" gradientUnits="userSpaceOnUse" x1="128.8121" y1="171.2221" x2="114.9561" y2="191.0821" gradientTransform="matrix(0.9995 3.013669e-02 3.013669e-02 -0.9995 333.0412 231.137)">
                        <stop  offset="0" style={{stopColor: "#000000"}}/>
                        <stop  offset="1" style={{stopColor: "#1E5381"}}/>
                    </linearGradient>
                
                    <circle id="nav_circle_fill_2a_00000044150763939264860390000003302975634759768735_" opacity="0.7" fill="url(#nav_circle_fill_2a_00000156573815173583123420000006640654611949684411_)" enableBackground="new" cx="465.4" cy="61.5" r="22.6"/>
                
                    <circle id="nav_circle_outline_2a_00000046298782756843014520000012706447493199574176_" fill="none" stroke="#E9ECF0" strokeWidth="1.5714" strokeLinecap="round" strokeMiterlimit="10" cx="465.4" cy="61.5" r="22.6"/>
                    <polygon id="nav_arrow_2a_00000080167081620487731090000015598398839204618887_" fill="#E9ECF0" points="456.1,73.2 461.7,61.4 
                        456.9,49.2 480.5,62"/>
            </g>
            <g id="success_check_back">
                <circle id="check_back_circle" stroke="#74A550" strokeMiterlimit="10" cx="884.8" cy="244.7" r="28.5"/>
            </g>
            <g id="success_check_front">
                <circle id="check_front_circle" stroke="#74A550" strokeWidth="4" strokeMiterlimit="10" cx="884.8" cy="244.7" r="18"/>
                
                    <polyline id="success_check" fill="none" stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
                    878.9,245.7 882.8,249.5 891.7,240.6 	"/>
            </g>
            <g id="avatar_1_group">
                
                    <linearGradient id="avatar_1_box_fill_00000166642617996213652690000012989519258580863418_" gradientUnits="userSpaceOnUse" x1="40.8207" y1="164.1959" x2="200.6376" y2="324.0127">
                    <stop  offset="0" style={{stopColor: "#9EAFC6"}}/>
                    <stop  offset="0.106" style={{stopColor: "#A1B0BF"}}/>
                    <stop  offset="0.2558" style={{stopColor: "#AAB4AD"}}/>
                    <stop  offset="0.4317" style={{stopColor: "#B9BB8F"}}/>
                    <stop  offset="0.6276" style={{stopColor: "#CEC464"}}/>
                    <stop  offset="0.8372" style={{stopColor: "#E8D02F"}}/>
                    <stop  offset="1" style={{stopColor: "#FFDA00"}}/>
                </linearGradient>
                
                    <rect id="avatar_1_box_fill" x="41" y="164.4" fill="url(#avatar_1_box_fill_00000166642617996213652690000012989519258580863418_)" width="155" height="155"/>
                <path id="avatar_1_body_fill" fill="#6784B4" d="M51.3,326.3c0.1-5.3,0.6-10.9,1.2-16c1.5-12,10-21.9,21.6-25.2
                    c3.4-1,5.8-1.5,5.8-1.5c17.6,7.7,53.7,7.7,71.3,0c0,0,2.3,0.5,5.8,1.5c11.6,3.4,20.1,13.3,21.6,25.2c0.6,5.1,1.1,10.7,1.2,16H51.3z
                    "/>
                
                    <path id="avatar_1_lines" fill="none" stroke="#003057" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
                    M110.2,269.1H105l-14.5-14.5l-1.7-9.1 M96.5,199.1c-3-1-7.8,0.4-10.8,3.8c-4.2,4.8-6.1,11.4-6.1,18.3c0,1.8,0.2,3.5,0.4,5.1
                    M83.3,280.2c4.5-2,7.6-4.3,9.9-6.6 M67.1,326.3v-7.7 M117.7,266.8l12.3-12.3l2-10.8 M140,228.8c0.4-1.9,0.8-4.4,0.8-7.6
                    c0-16.9-13.7-30.6-30.6-30.6c-8,0-10.6,6.7-10.6,6.7 M121.3,263.2c0,0,2.2,9.8,13.9,16 M174.5,326.3c-0.1-5.3-0.6-10.9-1.2-16
                    c-1.5-12-10-21.9-21.6-25.2c-3.4-1-5.8-1.5-5.8-1.5c-17.6,7.7-53.7,7.7-71.3,0c0,0-2.3,0.5-5.8,1.5c-11.6,3.4-20.1,13.3-21.6,25.2
                    c-0.6,5.1-1.1,10.7-1.2,16 M153.4,326.3v-7.7 M88.2,223c4.5-4.9,13.8-8.1,12-14.8c-0.3-1.3-0.7-2.4-0.9-3.2 M105.8,211.6
                    c0,0,11.6,10,28.1,13.6 M103.2,256.5c0,0,9.3,2.4,14.8-2.6 M121.5,235.3h-0.9h-0.9 M128.3,228.4c-8,0.5-13.2,3.3-13.2,3.3
                    c-1.9,4.3-1.9,7.5-1.9,7.5 M101.6,235.3h-0.9h-0.9 M104.8,226.3c-5.1,0.8-7.9,1.5-12.1,5 M83.1,235.8c-5.2-0.3-4.9,6-0.9,10.1
                    c4.5,4.6,7.3,3.3,7.3,3.3 M131,249.2c0,0,2.8,1.3,7.3-3.3c4-4.1,4.3-10.4-0.9-10.1"/>
                
                    <rect id="avatar_1_box_outline" x="34.1" y="171.3" fill="none" stroke="#E9ECF0" strokeWidth="1.25" strokeLinecap="round" strokeMiterlimit="10" width="155" height="155"/>
            </g>
            <g id="avatar_2_group">
                
                    <linearGradient id="avatar_2_box_fill_00000154387704728969906510000012635956753008433832_" gradientUnits="userSpaceOnUse" x1="41.0954" y1="319.4704" x2="194.5213" y2="165.6666">
                    <stop  offset="0" style={{stopColor: "#9EAFC6"}}/>
                    <stop  offset="0.4694" style={{stopColor: "#6F709D"}}/>
                    <stop  offset="1" style={{stopColor: "#37246B"}}/>
                </linearGradient>
                
                    <rect id="avatar_2_box_fill" x="41" y="164.4" fill="url(#avatar_2_box_fill_00000154387704728969906510000012635956753008433832_)" width="155" height="155"/>
                <path id="avatar_2_body_fill" fill="#37246B" d="M93,226.6h16.7v5c0,4.3-3.7,7.8-8.4,7.8c-4.6,0-8.4-3.5-8.4-7.8V226.6z
                    M117.2,231.7c0,4.3,3.7,7.8,8.4,7.8s8.4-3.5,8.4-7.8v-5h-16.7V231.7z M99.5,277.9c0,0-15.5,5.1-36.6,15.6
                    c-7.6,3.8-12.6,11.2-13.3,19.6c-0.3,4.1-0.4,8.6,0,13.1l133.5,0c0,0,0.3-9,0-13.1c-0.7-8.4-5.7-15.9-13.3-19.6
                    c-21.1-10.5-36.6-15.6-36.6-15.6C115.1,292.2,99.5,277.9,99.5,277.9z"/>
                <path id="avatar_2_lines" fill="none" stroke="#2D006C" strokeWidth="1.2454" strokeLinecap="round" strokeMiterlimit="10" d="
                    M132.6,226.6h-22.3H88 M84.5,230.5c-3.2,0.4-5.3,4.3-4.8,8.8c0.4,3.5,2.3,6.2,4.6,7.2 M89.9,231.1c0,4.6,3.7,8.4,8.4,8.4
                    c4.6,0,8.4-3.7,8.4-8.4 M88,243.1c1.1,26.2,13.9,31.1,22.3,31.1 M93.5,270.4v8.1c0,0-15.5,5.1-36.6,15.6
                    c-7.6,3.8-12.6,11.2-13.3,19.6c-0.3,3.9-0.4,8.2,0,12.5 M136.6,226.6c5-19.1-10.5-31.9-26.3-31.9S79,207.5,84,226.6 M130.8,221.6
                    l-1-5.6c-0.9-4.9-5.2-8.5-10.2-8.5h-9.3H101c-5,0-9.3,3.6-10.2,8.5l-1,5.6 M136.4,246.5c2.3-0.9,4.1-3.7,4.6-7.2
                    c0.5-4.5-1.6-8.5-4.8-8.8 M117.8,253.1h-7.4h-7.4 M114,231.1c0,4.6,3.7,8.4,8.4,8.4c4.6,0,8.4-3.7,8.4-8.4 M120.8,271.3
                    c5.9-3.8,11.1-11.8,11.8-28.2 M177.1,326.3c0.4-4.3,0.3-8.6,0-12.5c-0.7-8.4-5.7-15.9-13.3-19.6c-21.1-10.5-36.6-15.6-36.6-15.6
                    v-8.1 M127.2,285.9c-8.5,7-16.8,8.2-16.8,8.2v32.2 M93.5,285.9c3.4,2.8,6.8,4.7,9.7,5.9 M64.1,326.3v-6.9 M156.5,326.3v-6.9"/>
                
                    <rect id="avatar_2_box_outline" x="34.1" y="171.3" fill="none" stroke="#E9ECF0" strokeWidth="1.25" strokeLinecap="round" strokeMiterlimit="10" width="155" height="155"/>
            </g>
            <g id="avatar_3_group">
                
                    <linearGradient id="avatar_3_box_fill_00000010996441685657570350000008453084840802793873_" gradientUnits="userSpaceOnUse" x1="41.0954" y1="319.4704" x2="194.5213" y2="165.6666">
                    <stop  offset="0" style={{stopColor: "#74A750"}}/>
                    <stop  offset="0.1675" style={{stopColor: "#73A556"}}/>
                    <stop  offset="0.3966" style={{stopColor: "#719F68"}}/>
                    <stop  offset="0.6614" style={{stopColor: "#6D9585"}}/>
                    <stop  offset="0.9508" style={{stopColor: "#6887AC"}}/>
                    <stop  offset="1" style={{stopColor: "#6784B4"}}/>
                </linearGradient>
                
                    <rect id="avatar_3_box_fill" x="41" y="164.4" fill="url(#avatar_3_box_fill_00000010996441685657570350000008453084840802793873_)" width="155" height="155"/>
                <path id="avatar_3_body_fill" fill="#6784B4" d="M147.9,279.4c2-1.4,3.7-2.8,5.3-4.4l0,0l9.2,4.7c9.1,4.6,15.2,13.5,16.3,23.6
                    l2.6,23h-33.4V279.4z M125.8,287.5c-3,0.4-6.2,0.7-9.4,0.7c-15.8,0-29.6-5.5-37.2-13.7l0,0l-8.6,4.4c-9.1,4.6-15.2,13.5-16.3,23.6
                    l-2.7,23.8h74.2V287.5z"/>
                <path id="avatar_3_lines" fill="none" stroke="#003057" strokeWidth="1.25" strokeLinecap="round" strokeMiterlimit="10" d="
                    M111.4,207.6c11.8,0,21.4,9.6,21.4,21.4v9.7c0,11.8-9.6,21.4-21.4,21.4S90,250.6,90,238.8 M142.9,326.3V223.4
                    c0-17.4-14.1-31.5-31.5-31.5S80,206,80,223.4v47.8 M94.2,216.2c-0.6,0.8-1.2,1.7-1.7,2.6c-0.5,0.9-0.9,1.9-1.3,2.8
                    c-0.1,0.4-0.3,0.8-0.4,1.2 M102,201.9c0,0.8,0.2,1.5,0.4,2.2s0.7,1.3,1.2,1.8c0.3,0.3,0.6,0.5,0.9,0.8 M120.8,326.3V258
                    M103.3,246.2c0.8,0.2,1.6,0.4,2.5,0.5c0.9,0.1,1.8,0.3,2.7,0.3s1.9,0.1,2.9,0.1c1,0,2,0,2.9-0.1c0.9-0.1,1.8-0.2,2.7-0.3
                    c0.9-0.1,1.7-0.3,2.5-0.5 M110,235.8c0.8-3.9-0.8-6.8-0.8-6.8 M127.5,226.9c-0.4-0.5-0.9-0.9-1.5-1.3c-0.6-0.4-1.4-0.7-2.3-0.9
                    c-0.8-0.2-1.8-0.3-2.8-0.3c-1,0-1.9,0.1-2.8,0.3 M104.7,224.7c-0.8-0.2-1.8-0.3-2.8-0.3c-1,0-1.9,0.1-2.8,0.3
                    c-0.8,0.2-1.6,0.5-2.3,0.9s-1.2,0.8-1.5,1.3 M100.2,230h0.9h0.9 M120.8,230h0.9h0.9 M97.1,254.9L97.1,254.9c0,4.9-2.8,9.4-7.1,11.7
                    L65.6,279c-9.1,4.6-15.2,13.5-16.3,23.6l-2.7,23.6 M125.8,255.6 M176.2,326.3l-2.6-22.9c-1.1-10.1-7.3-19-16.3-23.6l-14.4-7.4
                    M74.2,274.6c7.6,8.2,21.4,13.7,37.2,13.7c3.2,0,6.4-0.2,9.4-0.7 M142.9,279.5c2-1.4,3.7-2.8,5.3-4.4"/>
                
                    <rect id="avatar_3_box_outline" x="34.1" y="171.3" fill="none" stroke="#E9ECF0" strokeWidth="1.25" strokeLinecap="round" strokeMiterlimit="10" width="155" height="155"/>
            </g>
            </svg>

        </div>
    );
}

export default BrainMap;
