const determineContent = (strategyType) => {
    let title = 'Take Strategy';
    let tags = ['MOSTLY AGGRESSIVE', 'LIQUIDITY TAKER'];
    let subtitle = 'Take liquidity for immediate risk transfer';
    let body = 'Our next-gen Auto-X RFQ offers you superior ease of use vs. legacy RFQ automation. The strategy prioritizes then uses dynamic, data-driven level guardrails with the option to retry after an attempted initial RFQ.';
    let dropdowns = {
        'DETERMINE THE MOST APPROPRIATE PRICE TOLERANCE' : 'Apply your own limit or MarketAxess’ curated impact models.',
        'ENHANCE HIGHER TOUCH INTERACTIONS' : 'Leverage more details on anticipated next actions or steps with the trade override option (trade, don’t trade).',
        'TAKE ADVANTAGE OF EVEN MORE QUOTES' : 'The strategy will consider Live Market’s “no last look” levels when assessing execution criteria.',
        'NEW ALERTING AND REAL TIME PERFORMANCE METRICS' : 'See details of responses to live orders and intervene or let the strategy continue to execute for you.',
    }
    if(strategyType === 'seek') {
        title = 'Seek Strategy';
        tags = ['FLEXIBLE', 'HYBRID LIQUIDITY'];
        subtitle = 'Seek liquidity with greater execution certainty';
        body = 'Take advantage of passive opportunities, while issuing RFQs to complete your order. You select the level of urgency and the strategy responds accordingly. The strategy will toggle between passive and aggressive approaches to drive execution.';
        dropdowns = {
            'Urgency' : 'Specify trading intensity in terms of pricing, size and RFQ frequency depending on trader prescribed urgency levels (low, medium, high).',
            'Cost Impact Modeling' : 'Similar to Auto-X RFQ, synthetically limit the level at which the strategy can trade when aggressing, spread-normalized relative to CP+.',
            'RFQ Frequency' : 'Specify how often you want to trade in the market, finding the right balance of liquidity seeking behavior.',
            'Liquidity Type Specific Pricing' : 'Designate venue specific pricing depending on the liquidity taking vs. liquidity making portions of your order.',
            'Venue Specific Sizing' : 'Customize minimum and maximum size preferences unique to certain liquidity pools.',
            'Optional Anonymity' : 'Aggress while using discretion in leveraging your counterparty status depending on the situation, or stay fully anonymous for larger buy/sell orders.',
        }
    } else if(strategyType === 'provide') {
        title = 'Provide Strategy';
        tags = ['LOW URGENCY', 'LIQUIDITY MAKER'];
        subtitle = 'Provide liquidity while picking up bid/ask spread';
        body = 'Take an opportunistic journey. Rather than demand immediacy to the market, you can source the bonds you need (or sell them) in an anonymous and passive way, without signaling to the market. The strategy emphasizes Auto-X Responder and Live Markets passively.';
        dropdowns = {
            'Venue & liquidity pool selection' : 'Leverage your choice of anonymously and passively responding to Request for Quotes (RFQs).',
            'Passive Pricing' : 'Work your own orders using your PM’s discretionary limit. Designate venue-specific pricing depending on your order intent and view of the market by dynamically pegging to CP+ pricing (vs near touch, mid-point, or spread percentile).',
            'Customizable Sizing' : 'Tailor minimum and maximum size preferences unique to each liquidity pool.',
            'Full Anonymity' : 'Trade anonymously through our Open Trading™ network, leveraging various order types.',
            'Scalability' : 'Apply strategies at the individual order, multiple order, or basket level to a set limit. Pause and play strategies on demand.',
        }
    }

    return {
        title,
        tags,
        subtitle,
        body,
        dropdowns
    };
}

export default determineContent;
