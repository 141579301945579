/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-constructor */
/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unescaped-entities */
import React, {useRef, useEffect, useState, Suspense} from 'react';
import Lottie from "lottie-react";
import orderRoute1 from '../animations/landing/order_route_1.json';
import orderRoute2 from '../animations/landing/order_route_2.json';
import orderRoute3 from '../animations/landing/order_route_3.json';
import orderRoute4 from '../animations/landing/order_route_4.json';
import {animateLandingCards} from '../animations/landing/LandingAnimationTimeline';


const scrollToStrategies = () => {
    const strategiesSection = document.getElementById('autoX__landingStrategies');
    strategiesSection.scrollIntoView({behavior: "smooth"});
}

const toggleVideo = () => {
    const video = document.getElementById('autoX__heroVideo');
    const toggleButton = document.querySelector('.autoX__heroVideoToggle');
    if(video.paused) {
        video.play();
        toggleButton.innerHTML = '<span class="fa-solid fa-pause"/><span class="screen-reader-text">Pause Video</span>';
    } else {
        video.pause();
        toggleButton.innerHTML = '<span class="fa-solid fa-play"/><span class="screen-reader-text">Play Video</span>';
    }
}

const isElementInViewport = (el) => {
    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight + 200 || document.documentElement.clientHeight + 200) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}

const LandingPage = () => {

    const orderRouteRef1 = useRef();
    const orderRouteRef2 = useRef();
    const orderRouteRef3 = useRef();
    const orderRouteRef4 = useRef();

    useEffect(() => {
        // Initialize gsap animations
        animateLandingCards(orderRouteRef1, orderRouteRef2, orderRouteRef3, orderRouteRef4);
    }, []);

    document.addEventListener('scroll', () => {
        if(isElementInViewport(document.getElementById('autoX__landingStrategies'))) {
            const progressDots = document.getElementsByClassName('autoX__headerProgressStep');
            if(progressDots.length > 1) {
                progressDots[1].classList.add('autoX__headerProgressStep--active');
            }
        } else {
            const progressDots = document.getElementsByClassName('autoX__headerProgressStep');
            if(progressDots.length > 1) {
                progressDots[1].classList.remove('autoX__headerProgressStep--active');
            }
        }
    });


    return (
        <div className="autoX__landingPage">

            <section className="autoX__hero">
                <div className="container">

                    <div className="autoX__heroColumn autoX__heroColumn--text">
                        <div className="autoX__heroTitle">
                            <h1>Adaptive Auto-X<sup>TM</sup></h1>
                        </div>
                        <div className="autoX__heroBody">
                            <h2>Make the market work for you</h2>
                            <p>Whatever your objectives, now you can address the market in multiple ways with a single order using our Smart Order Routing algo strategies. Amplify your best trading capabilities by leveraging a variety of MarketAxess liquidity pools at the same time.</p>
                        </div>

                        <button onClick={scrollToStrategies} className="autoX__ctaButton">Select your strategy<span className="autoX__ctaButtonArrow"></span></button>
                    </div>

                    <div className="autoX__heroColumn">
                        <video id="autoX__heroVideo" autoPlay muted loop playsInline>
                            <source src="/videos/HeroVideo_Loop.mp4" type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        <button onClick={toggleVideo} className="autoX__heroVideoToggle"><span className="fa-solid fa-pause"/><span className="screen-reader-text">Pause Video</span></button>
                    </div>

                </div>
            </section>


            <section className="autoX__landingAnimations">
                <div className="container">

                    <h2 className="autoX__landingAnimationsTitle">Execution by advanced Smart Order Routing technology</h2>

                    <div className="autoX__landingAnimationsWrapper" id="autoX__landingAnimationsWrapper">
                        <div className="autoX__landingAnimationCard">
                            <h3>DO MORE WITH YOUR ORDER</h3>
                            <p>Engage in multiple liquidity pools at the same time through one central access point.</p>
                            <Lottie animationData={JSON.parse(JSON.stringify(orderRoute1))} lottieRef={orderRouteRef1} loop={false}/>
                        </div>

                        <div className="autoX__landingAnimationCard">
                            <h3>Execute more effectively</h3>
                            <p>Orders are optimized in size and then routed to find the most appropriate protocol.</p>
                            <Lottie animationData={JSON.parse(JSON.stringify(orderRoute2))} lottieRef={orderRouteRef2}  loop={false}/>
                        </div>

                        <div className="autoX__landingAnimationCard">
                            <h3>Reduce time constraints</h3>
                            <p>Adapt as the market moves. Wait for the right price, prioritize completion, or combine objectives to execute across a spectrum of priorities.</p>
                            <Lottie animationData={JSON.parse(JSON.stringify(orderRoute3))} lottieRef={orderRouteRef3}  loop={false}/>
                        </div>

                        <div className="autoX__landingAnimationCard">
                            <h3>Leverage YOUR intelligent assistant</h3>
                            <p>Smart Order Routing uses multiple protocols to maximize connectivity with proprietary real-time data and signals.</p>
                            <Lottie animationData={JSON.parse(JSON.stringify(orderRoute4))} lottieRef={orderRouteRef4}  loop={false}/>
                        </div>
                    </div>

                </div>
            </section>


            <section className="autoX__landingStrategies" id="autoX__landingStrategies">
                <div className="container">

                    <h2 className="autoX__landingStrategiesTitle">Select which strategy matches your priorities</h2>
                    <h2 className="autoX__landingStrategiesMobileTitle">Strategy that matches your priorities</h2>

                    <div className="autoX__landingStrategiesOptions">
                        <div className="autoX__landingStrategyCard">
                            <a href="/lp/autox/strategy/take" className="autoX__landingStrategyTarget"></a>
                            <h3>Take Strategy</h3>
                            <div className="autoX__landingStrategyTags">
                                <span className="autoX__strategyTag__urgency">Highest Urgency</span>
                                <span className="autoX__strategyTag__liquidity">Liquidity Taker</span>
                            </div>
                            <p>Completion is emphasized</p>
                            <p>Cost savings is not a priority</p>
                        </div>
                        <div className="autoX__landingStrategyCard">
                            <a href="/lp/autox/strategy/seek" className="autoX__landingStrategyTarget"></a>
                            <h3>Seek Strategy</h3>
                            <div className="autoX__landingStrategyTags">
                                <span className="autoX__strategyTag__urgency">Flexible Urgency</span>
                                <span className="autoX__strategyTag__liquidity">MAKE & TAKE LIQUIDITY</span>
                            </div>
                            <p>Completion is preferred</p>
                            <p>Lowest transaction cost required</p>
                        </div>
                        <div className="autoX__landingStrategyCard">
                            <a href="/lp/autox/strategy/provide" className="autoX__landingStrategyTarget"></a>
                            <h3>Provide Strategy</h3>
                            <div className="autoX__landingStrategyTags">
                                <span className="autoX__strategyTag__urgency">LOW URGENCY</span>
                                <span className="autoX__strategyTag__liquidity">LIQUIDITY MAKER</span>
                            </div>
                            <p>Cost savings is priority</p>
                            <p>Completion not required</p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="autoX__mobileLanding">
                <div className="container">
                    <h2>Best viewed on desktop</h2>
                    <button onClick={async() => {
                        const shareData = {
                            url: window.location.href,
                            title: 'MarketAxess',
                            text: 'Check out MarketAxess AutoX',
                        };
                        if(navigator.share && navigator.canShare(shareData)) {
                            try {
                                await navigator.share(shareData);
                            } catch(err) {
                                console.log('Error sharing:', err);
                            }
                        }
                    }}>Share page</button>
                    <p>See our Smart Order Routing strategies in action</p>
                </div>
            </section>

            <section className="autoX__contactUs">
                <div className="container">
                    <div className="autoX__contactUsContent">
                        <h2>Contact MarketAxess to learn more about how your trading can benefit from Adaptive Auto-X.</h2>
                        <a href="/lp/autox/contact-us" className="autoX__contactUsCta">Contact us</a>
                    </div>
                </div>
            </section>

        </div>
    );
    
}

export default LandingPage;
