/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import React, {useState} from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(MotionPathPlugin);

let pastTimelines = [];
let optionSelected = false;
let slowPathStarted = false;
let fastPathStarted = false;

export function animateSeekStrategyBrainMap() {


    // Hide profile pictures for other two strategies
    gsap.set("#avatar_1_group, #avatar_3_group", {opacity: 0});

    // Set handler for slider option card
    document.querySelector("#seekOptionSlider").addEventListener("change", (e) => {
        if(e.target.value >= 51 && !optionSelected && !fastPathStarted) {
            optionSelected = true;
            fastPathStarted = true;
            slowPathStarted = false;
            startFasterAnimation();
        } else if(e.target.value >= 51 && optionSelected && !fastPathStarted) {
            slowPathStarted = false;
            fastPathStarted = true;
            previousAnimationStep(null, true, 'switching', startFasterAnimation);
        } else if(e.target.value <= 49 && !optionSelected && !slowPathStarted) {
            optionSelected = true;
            slowPathStarted = true;
            fastPathStarted = false;
            startSlowerAnimation();
        } else if(e.target.value <= 49 && optionSelected && !slowPathStarted) {
            fastPathStarted = false;
            slowPathStarted = true;
            previousAnimationStep(null, true, 'switching', startSlowerAnimation);
        } else if(e.target.value > 49 && e.target.value < 51 && optionSelected) {
            optionSelected = false;
            fastPathStarted = false;
            slowPathStarted = false;
            previousAnimationStep();
        }
    });

    document.querySelector("#autoX__strategyActionButton").addEventListener("click", startAnimation);
    document.querySelector("#seekStrategySlowNextButton").addEventListener("click", continueSlowerAnimation);
    document.querySelector("#seekStrategyFastNextButton").addEventListener("click", continueFasterAnimation);
    document.querySelector("#seekStrategyEndButton").addEventListener("click", endingAnimation);
    document.querySelector("#autoX__strategyBackNav").addEventListener("click", previousAnimationStep);
}

function startAnimation() {
    // Main timeline
    const seekStrategyTimeline1 = gsap.timeline({});
    pastTimelines.push(seekStrategyTimeline1);

    gsap.set("#spinner_1, #spinner_2, #spinner_3, #spinner_4, #spinner_5, #spinner_6, #spinner_7, #spinner_8, #spinner_9, #spinner_10, #spinner_11, #spinner_12, #spinner_13, #spinner_14, #spinner_15, #spinner_16, #spinner_17, #spinner_18, #spinner_19", {opacity: 0});

    // Spinners Fade Away Timeline
    const seekStrategyTimeline2 = gsap.timeline({});
    setupStrategyTimeline2(seekStrategyTimeline2);

    seekStrategyTimeline1.add('seekStrategyStart')
    .to("#autoX__strategyActionButton", {y: -100, opacity: 0, display: 'none', duration: 0.6}, 'seekStrategyStart')
    .fromTo("#takeStrategyPrompt1", {opacity: 0, y: 100}, {opacity: 1, y: 0, duration: 0.6}, 'seekStrategyStart+=0.3')
    .fromTo("#headerProgressDot", {opacity: 0, scale: 0, transformOrigin: "center"}, {opacity: 1, scale: 1, duration: 0.6}, 'seekStrategyStart')
    .fromTo("#headerProgressDot", {left: "0%", ease: 'linear'}, {left: '45%', duration: 9}, 'seekStrategyStart+=0.3')
    .fromTo("#headerProgressAnimatedLine", {width: "0%"}, {width: "46%", duration: 6, ease: 'linear'}, 'seekStrategyStart+=0.5')
    // Status Orb to "loading" state
    .fromTo("#statusOrb", {opacity: 0, display: "block"}, {opacity: 1, duration: 0.6}, 'seekStrategyStart+=0.5')
    .fromTo("#x-orb", {scale: 1, transformOrigin: "center"}, {scale: 1.4, duration: 0.6}, 'seekStrategyStart+=0.5')
    .fromTo("#loading",{opacity: 0}, {opacity: 1, duration: 0.6,}, 'seekStrategyStart+=0.8')
    .fromTo("#x-loading",{scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.6,}, 'seekStrategyStart+=0.6')
    .to("#loading", {transformOrigin: "center", duration: 0.8, rotation:"+=360",repeat:-1, ease: 'linear'}, 'seekStrategyStart+=0.6')
    .fromTo("#autoX__takeStrategyStatusText1", {opacity: 0, y: 100, display: "block"}, {opacity: 1, y: 0, duration: 0.6}, 'seekStrategyStart+=0.6')
    // Spinners
    .call(() => {
        startSpinnerAnimations(seekStrategyTimeline1, 'seekStrategyStart', 1.5, 0.05);
    }, null, 'seekStrategyStart+=1.5')
    .add(seekStrategyTimeline2, 'seekStrategyStart+=3.5')
}

function setupStrategyTimeline2(timeline) {
    MotionPathPlugin.convertToPath("#order_path_solid_2b");

    timeline.add('spinnersFadeAway')
    .fromTo("#spinner_1", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2')
    .fromTo("#spinner_2", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.05')
    .fromTo("#spinner_3", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.1')
    .fromTo("#spinner_4", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.15')
    .fromTo("#spinner_5", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.2')
    .fromTo("#spinner_6", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.25')
    .fromTo("#spinner_7", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.3')
    .fromTo("#spinner_8", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.35')
    .fromTo("#spinner_9", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.4')
    .fromTo("#spinner_10", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.45')
    .fromTo("#spinner_11", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.5')
    .fromTo("#spinner_12", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.55')
    .fromTo("#spinner_13", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.6')
    .fromTo("#spinner_14", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.65')
    .fromTo("#spinner_15", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.7')
    .fromTo("#spinner_16", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.75')
    .fromTo("#spinner_17", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.8')
    .fromTo("#spinner_18", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.85')
    .fromTo("#spinner_19", {scale: 1, transformOrigin: "center"}, {duration: 0.2, scale: 0}, 'spinnersFadeAway+=2.9')
    // Transition status text
    .call(() => {
        transitionStatusText(timeline,"#autoX__takeStrategyStatusText1", "#autoX__takeStrategyStatusText2", 'spinnersFadeAway+=3', 'spinnersFadeAway+=3.6');
    }, null, 'spinnersFadeAway+=2')
    // Status Orb to "loading" state
    .fromTo("#loading",{opacity: 1}, {opacity: 0, duration: 0.8,}, 'spinnersFadeAway+=3')
    .fromTo("#x-loading",{scale: 1, transformOrigin: "center"}, {scale: 1.4, duration: 0.8,}, 'spinnersFadeAway+=3')
    // Animate beginning of path 2b first
    .call(() => {
        animatedDottedLine(timeline, "#order_dotted_2b", "#order_path_dotted_2b_mask_path", "0%", "15%", 0.8, 'spinnersFadeAway+=3');
    }, null, 'spinnersFadeAway+=3')
    // Animate the three paths
    .call(() => {
        animatedDottedLine(timeline, "#order_dotted_2a", "#order_path_dotted_2a_mask_path", "0%", "33%", 1.5, 'spinnersFadeAway+=3.8');
    }, null, 'spinnersFadeAway+=3.6')
    .call(() => {
        animatedDottedLine(timeline, "#order_dotted_2b", "#order_path_dotted_2b_mask_path", "15%", "43%", 2, 'spinnersFadeAway+=5');
    }, null, 'spinnersFadeAway+=5')
    .call(() => {
        animatedDottedLine(timeline, "#order_dotted_2c", "#order_path_dotted_2c_mask_path", "0%", "48%", 2, 'spinnersFadeAway+=6.8');
    }, null, 'spinnersFadeAway+=6')
    .fromTo("#check_mark_1a",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=4')
    .fromTo("#check_mark_2a",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=4.2')
    .fromTo("#check_mark_2b",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=5.3')
    .fromTo("#check_mark_2e",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=5.6')
    .fromTo("#check_mark_1c",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=7')
    .fromTo("#check_mark_2g",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=7.4')
    .fromTo("#check_mark_2h",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=8.2')
    .fromTo("#check_mark_2i",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'spinnersFadeAway+=8.8')
    // Start solid lines animation
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_2b", "#order_path_solid_2b", "0%", "15%", 0.8, 'spinnersFadeAway+=8');
    }, null, 'spinnersFadeAway+=8')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_2a", "#order_path_solid_2a", "0%", "31%", 1.4, 'spinnersFadeAway+=8.8');
    }, null, 'spinnersFadeAway+=8.8')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_2b", "#order_path_solid_2b", "15%", "43%", 1.2, 'spinnersFadeAway+=9.2');
    }, null, 'spinnersFadeAway+=9.2')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_2c", "#order_path_solid_2c", "0%", "48%", 1.4, 'spinnersFadeAway+=9.8');
    }, null, 'spinnersFadeAway+=9.8')
    // Move nav arrow along path 2b
    .to("#nav_arrow_group_1a", {opacity: 1, duration: 0.1}, 'spinnersFadeAway+=10')
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_1a", "#order_path_solid_2b", 2, 0, 0.38, 'spinnersFadeAway+=10');
    }, null, 'spinnersFadeAway+=10')
    // Show glowing waiting dot on path 2b
    .to("#check_mark_1c", {opacity: 0, scale: 0, duration: 0.4}, 'spinnersFadeAway+=12')
    .fromTo("#waiting_dot_3a", {opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1.4, duration: 0.6}, 'spinnersFadeAway+=12')
    .fromTo("#waiting_dot_3a", {boxShadow: "0px 0px 20px #FFDD00"}, {boxShadow: "0px 0px 8px #FFDD00", duration: 2, repeat: -1, ease: "linear"}, 'spinnersFadeAway+=12')
    // Transition status text & show x-orb glow
    .call(() => {
        transitionStatusText(timeline,"#autoX__takeStrategyStatusText2", "#autoX__takeStrategyStatusText3", 'spinnersFadeAway+=13', 'spinnersFadeAway+=13.6');
    }, null, 'spinnersFadeAway+=13')
    .to("#yellow-glow", {opacity: 1, duration: 0.6}, 'spinnersFadeAway+=13')
    // .fromTo("#yellow-glow", {scale: 0.9, transformOrigin: "center", ease: "linear"}, {scale: 1, duration: 1, repeat: -1, yoyo: true}, 'spinnersFadeAway+=13')
    // Show first option slider card
    .fromTo("#seekStrategyOption1", {opacity: 0, y: 100}, {opacity: 1, y: 0, display: 'flex', duration: 0.6}, 'spinnersFadeAway+=13')
    .to("#takeStrategyPromptDottedLine1", {opacity: 1}, 'spinnersFadeAway+=13')
}

function startSlowerAnimation() {
    const timeline = gsap.timeline({});
    pastTimelines.push(timeline);

    MotionPathPlugin.convertToPath("#reattempt_line_dotted_2a_mask_path");

    timeline.add('slowStart')
    // Animate dotted loop line
    .call(() => {
        animatedDottedLine(timeline, "#reattempt_dotted_2a", "#reattempt_line_dotted_2a_mask_path", "0%", "100%", 2, 'slowStart');
    }, null, 'slowStart')
    // Transition status text
    .call(() => {
        transitionStatusText(timeline,"#autoX__takeStrategyStatusText3", "#autoX__takeStrategyStatusText4", 'slowStart+=1.3', 'slowStart+=1.9');
    }, null, 'slowStart+=1')
    // Move nav arrow around loop 3 times
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_1a", "#reattempt_line_dotted_2a_mask_path", 2, 0.15, 1, 'slowStart+=2');
    }, null, 'slowStart+=2')
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_1a", "#reattempt_line_dotted_2a_mask_path", 2, 0, 1, 'slowStart+=4.05');
    }, null, 'slowStart+=4.05')
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_1a", "#reattempt_line_dotted_2a_mask_path", 2, 0, 1, 'slowStart+=6.1');
    }, null, 'slowStart+=6.1')
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_1a", "#reattempt_line_dotted_2a_mask_path", 0.4, 0, 0.15, 'slowStart+=8.15');
    }, null, 'slowStart+=8.15')
    //Continue path a and c dotted lines
    .call(() => {
        animatedDottedLine(timeline, "#order_dotted_2a", "#order_path_dotted_2a_mask_path", "33%", "77%", 3, 'slowStart+=4.1');
    }, null, 'slowStart+=4.1')
    .call(() => {
        animatedDottedLine(timeline, "#order_dotted_2c", "#order_path_dotted_2c_mask_path", "48%", "75%", 3, 'slowStart+=4.1');
    }, null, 'slowStart+=4.1')
    .fromTo("#check_mark_2c",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'slowStart+=5.2')
    .fromTo("#check_mark_2d",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'slowStart+=7.1')
    .fromTo("#check_mark_2j",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'slowStart+=5.1')
    .fromTo("#seekStrategySlowNextButton", {opacity: 0, y: 100}, {display: "flex", opacity: 1, y: 0, duration: 0.6}, 'slowStart')
    .fromTo("#headerProgressAnimatedLine",{width: "46%"}, {width: "68%", duration: 6, ease: 'linear'}, 'slowStart')
    .fromTo("#headerProgressDot", {left: '45%'}, {left: '68%', duration: 9, ease: 'linear'}, 'slowStart')

}

function startFasterAnimation() {
    const timeline = gsap.timeline({});
    pastTimelines.push(timeline);

    timeline.add('fastPath')
    // Transition status text
    .call(() => {
        transitionStatusText(timeline,"#autoX__takeStrategyStatusText3", "#autoX__takeStrategyStatusText5", 'fastPath', 'fastPath+=0.6');
    }, null, 'fastPath')
    // Animate the three dotted lines
    .call(() => {
        animatedDottedLine(timeline, "#order_dotted_2a", "#order_path_dotted_2a_mask_path", "33%", "77%", 3, 'fastPath');
    }, null, 'fastPath')
    .call(() => {
        animatedDottedLine(timeline, "#order_dotted_2c", "#order_path_dotted_2c_mask_path", "48%", "75%", 3, 'fastPath');
    }, null, 'fastPath')
    .call(() => {
        animatedDottedLine(timeline, "#order_dotted_2b", "#order_path_dotted_2b_mask_path", "43%", "77%", 1.4, 'fastPath');
    }, null, 'fastPath')
    .fromTo("#check_mark_2c",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'fastPath+=1')
    .fromTo("#check_mark_2d",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'fastPath+=3')
    .fromTo("#check_mark_2j",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'fastPath+=1')
    .fromTo("#seekStrategyFastNextButton", {opacity: 0, y: 100}, {display: "flex", opacity: 1, y: 0, duration: 0.6}, 'fastPath')
    .fromTo("#headerProgressAnimatedLine",{width: "46%"}, {width: "68%", duration: 4, ease: 'linear'}, 'fastPath')
    .fromTo("#headerProgressDot", {left: '45%'}, {left: '68%', duration: 5, ease: 'linear'}, 'fastPath')
}

function continueSlowerAnimation() {
    // Make sure last timeline is finished
    if(pastTimelines.length > 0) {
        pastTimelines[pastTimelines.length - 1].totalProgress(1);
    }

    const timeline = gsap.timeline({});
    pastTimelines.push(timeline);

    MotionPathPlugin.convertToPath("#order_path_solid_2a");
    MotionPathPlugin.convertToPath("#order_path_solid_2c");

    timeline.add('slowPath')
    // Hide elements to move to next step
    .fromTo("#headerProgressAnimatedLine", {width: "68%"}, {width: "80%", duration: 2, ease: 'linear'}, 'slowPath')
    .fromTo("#headerProgressDot", {left: "68%"}, {left: '80%', duration: 3, ease: 'linear'}, 'slowPath')
    .to("#reattempt_dotted_2a", {opacity: 0, duration: 0.6}, 'slowPath')
    .to("#yellow-glow", {opacity: 0, duration: 0.6}, 'slowPath')
    .to("#seekStrategySlowNextButton", {x: 100, opacity: 0, display: "none", duration: 0.4}, 'slowPath')
    .to("#seekStrategyOption1", {opacity: 0, x: 100, display: 'none', duration: 0.4}, 'slowPath+=0.2')
    // Transition status text
    .call(() => {
        transitionStatusText(timeline,"#autoX__takeStrategyStatusText4", "#autoX__takeStrategyStatusText6", 'slowPath+=0.2', 'slowPath+=0.8');
    }, null, 'slowPath+=0.2')
    // Animate path b dotted line
    .call(() => {
        animatedDottedLine(timeline, "#order_dotted_2b", "#order_path_dotted_2b_mask_path", "43%", "77%", 1.4, 'slowPath+=0.2');
    }, null, 'slowPath+=0.2')
    .fromTo("#seekStrategyOption2", {opacity: 0, display: "none", y: 100}, {opacity: 1, y: 0, display: 'flex', duration: 0.6}, 'slowPath+=0.8')
    .fromTo("#seekStrategyEndButton", {opacity: 0, y: 100}, {display: "flex", opacity: 1, y: 0, duration: 0.6}, 'slowPath+=1')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_2a", "#order_path_solid_2a", "31%", "77%", 2, 'slowPath+=1');
    }, null, 'slowPath+=1')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_2b", "#order_path_solid_2b", "43%", "78%", 2, 'slowPath+=1');
    }, null, 'slowPath+=1')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_2c", "#order_path_solid_2c", "48%", "75%", 2, 'slowPath+=1');
    }, null, 'slowPath+=1')
    .to("#waiting_dot_3a",{opacity: 0, duration: 0.6}, 'slowPath+=1')
    // Show nav arrows on paths A and C
    .to("#nav_arrow_group_2a", {opacity: 1, duration: 0.1}, 'slowPath+=1.2')
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_2a", "#order_path_solid_2a", 1.4, 0.15, 0.41, 'slowPath+=1.2', true);
    }, null, 'slowPath+=1.2')
    .call(() => {
        const navArrow = document.querySelector("#nav_arrow_group_1a");
        navArrow.classList.toggle("navArrow--blueGlow");
    }, null, 'slowPath+=1.3')
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_1a", "#order_path_solid_2b", 1.4, 0.35, 0.5, 'slowPath+=1.2');
    }, null, 'slowPath+=1.2')
    .to("#nav_arrow_group_2c", {opacity: 1, duration: 0.1}, 'slowPath+=1.2')
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_2c", "#order_path_solid_2c", 1.4, 0.35, 0.55, 'slowPath+=1.2');
    }, null, 'slowPath+=1.2')
    // Show blue glow on nav arrows
    // Show checkmarks along paths A and C
    .fromTo("#check_mark_1c",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'slowPath+=1.4')
    .fromTo("#check_mark_1d",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'slowPath+=1.4')
    .fromTo("#check_mark_2k",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'slowPath+=2.6')
    .fromTo("#check_mark_2f",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'slowPath+=2.6')
}

function continueFasterAnimation() {
    // Make sure last timeline is finished
    if(pastTimelines.length > 0) {
        pastTimelines[pastTimelines.length - 1].totalProgress(1);
    }

    const timeline = gsap.timeline({});
    pastTimelines.push(timeline);

    MotionPathPlugin.convertToPath("#order_path_solid_2a");
    MotionPathPlugin.convertToPath("#order_path_solid_2c");

    timeline.add('continueFastPath')
    // Hide elements to move to next step
    .call(() => {
        transitionStatusText(timeline,"#autoX__takeStrategyStatusText5", "#autoX__takeStrategyStatusText6", 'continueFastPath', 'continueFastPath+=0.6');
    }, null, 'continueFastPath')
    .fromTo("#headerProgressAnimatedLine", {width: "68%"}, {width: "80%", duration: 2, ease: 'linear'}, 'continueFastPath')
    .fromTo("#headerProgressDot", {left: "68%"}, {left: '80%', duration: 3, ease: 'linear'}, 'continueFastPath')
    .to("#yellow-glow", {opacity: 0, duration: 0.6}, 'continueFastPath')
    .to("#seekStrategyFastNextButton", {x: 100, opacity: 0, display: "none", duration: 0.4}, 'continueFastPath')
    .to("#seekStrategyOption1", {opacity: 0, x: 100, display: 'none', duration: 0.4}, 'continueFastPath+=0.2')
    // Transition prompt card and button
    .fromTo("#seekStrategyOption2", {opacity: 0, display: "none", y: 100}, {opacity: 1, y: 0, display: 'flex', duration: 0.6}, 'continueFastPath+=0.8')
    .fromTo("#seekStrategyEndButton", {opacity: 0, y: 100}, {display: "flex", opacity: 1, y: 0, duration: 0.6}, 'continueFastPath+=1')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_2a", "#order_path_solid_2a", "31%", "77%", 2, 'continueFastPath+=1');
    }, null, 'continueFastPath+=1')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_2b", "#order_path_solid_2b", "43%", "78%", 2, 'continueFastPath+=1');
    }, null, 'continueFastPath+=1')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_2c", "#order_path_solid_2c", "48%", "75%", 2, 'continueFastPath+=1');
    }, null, 'continueFastPath+=1')
    .to("#waiting_dot_3a",{opacity: 0, duration: 0.6}, 'continueFastPath+=1')
    // Show nav arrows on paths A and C
    .to("#nav_arrow_group_2a", {opacity: 1, duration: 0.1}, 'continueFastPath+=1.2')
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_2a", "#order_path_solid_2a", 1.4, 0.15, 0.41, 'continueFastPath+=1.2', true);
    }, null, 'continueFastPath+=1.2')
    .call(() => {
        const navArrow = document.querySelector("#nav_arrow_group_1a");
        navArrow.classList.toggle("navArrow--blueGlow");
    }, null, 'continueFastPath+=1.3')
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_1a", "#order_path_solid_2b", 1.4, 0.35, 0.5, 'continueFastPath+=1.2');
    }, null, 'continueFastPath+=1.2')
    .to("#nav_arrow_group_2c", {opacity: 1, duration: 0.1}, 'continueFastPath+=1.2')
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_2c", "#order_path_solid_2c", 1.4, 0.35, 0.55, 'continueFastPath+=1.2');
    }, null, 'continueFastPath+=1.2')
    // Show blue glow on nav arrows
    // Show checkmarks along paths A and C
    .fromTo("#check_mark_1c",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'continueFastPath+=1.4')
    .fromTo("#check_mark_1d",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'continueFastPath+=1.4')
    .fromTo("#check_mark_2k",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'continueFastPath+=2.6')
    .fromTo("#check_mark_2f",{opacity: 1, scale: 0, transformOrigin: "center"}, {scale: 1, duration: 0.5}, 'continueFastPath+=2.6')
}

function endingAnimation() {
    const timeline = gsap.timeline({});
    pastTimelines.push(timeline);

    timeline.add('ending')
    .call(() => {
        const navArrow = document.querySelector("#nav_arrow_group_1a");
        navArrow.classList.toggle("navArrow--blueGlow");
    }, null, 'ending+=0.1')
    .fromTo("#headerProgressAnimatedLine", {width: "80%"}, {width: "95%", duration: 2, ease: 'linear'}, 'ending')
    .fromTo("#headerProgressDot", {left: '80%'}, {left: '99%', duration: 3, ease: 'linear'}, 'ending')
    .to("#headerProgressDot", {opacity: 0, duration: 0.2}, 'ending+=2.8')
    .call(() => {
        const progressStep = document.getElementById("autoX__headerProgressStep4");
        if(progressStep.className.includes('autoX__headerProgressStep--active')) {
            progressStep.classList.remove('autoX__headerProgressStep--active');
        } else {
            progressStep.classList.add('autoX__headerProgressStep--active');
        }
    }, null, 'ending+=2.8')
    // Hide next button and prompt card
    .to("#seekStrategyOption2",  {opacity: 0, display: "none", duration: 0.6}, 'ending')
    .to("#takeStrategyPromptDottedLine1", {opacity: 0}, 'ending')
    .to("#seekStrategyEndButton", { opacity: 0, x: 100, display: "none", duration: 0.6}, 'ending')
    // Hide other nav arrows
    .to("#nav_arrow_group_2a", {opacity: 0, duration: 0.1}, 'ending')
    .to("#nav_arrow_group_2c", {opacity: 0, duration: 0.1}, 'ending')
    // Animate all solid paths to completion
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_2a", "#order_path_solid_2a", "77%", "100%", 1.4, 'ending');
    }, null, 'ending')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_2b", "#order_path_solid_2b", "78%", "100%", 1.4, 'ending');
    }, null, 'ending')
    .call(() => {
        animatedDottedLine(timeline, "#order_solid_2c", "#order_path_solid_2c", "75%", "100%", 1.4, 'ending');
    }, null, 'ending')
    // Move arrow on path B to ending
    .call(() => {
        moveNavArrow(timeline, "#nav_arrow_group_1a", "#order_path_solid_2b", 1.4, 0.5, 0.95, 'ending+=0.6');
    }, null, 'ending+=0.6')
    // Show success checkmark and glow on x-orb
    .to("#green-glow", {opacity: 1, duration: 0.6}, 'ending+=2')
    .fromTo("#success_check_front", {opacity: 0, scale: 0, transformOrigin: "center"}, {opacity: 1, scale: 1.2, duration: 0.4}, 'ending+=2')
    .to("#success_check_front", { scale: 1, duration: 0.2, transformOrigin: "center"}, 'ending+=2')
    .fromTo("#success_check_back", {opacity: 0, scale: 0, transformOrigin: "center"}, {opacity: 1, scale: 1.2, duration: 0.4}, 'ending+=2')
    .to("#success_check_back", { scale: 1, duration: 0.2, transformOrigin: "center"}, 'ending+=2')
    // Transition status text and final prompt card
    .call(() => {
        transitionStatusText(timeline,"#autoX__takeStrategyStatusText6", "#autoX__takeStrategyStatusText7", 'ending+=2', 'ending+=2.6');
    }, null, 'ending+=2')
    .to("#takeStrategyPrompt1", {opacity: 0, display: "none", duration: 0.4}, 'ending+=2')
    .fromTo("#seekStrategyOption3", {opacity: 0, display: "none", y: 100}, {opacity: 1, y: 0, display: "flex", duration: 0.6}, 'ending+=2.4')
}

function previousAnimationStep(e, sliderToggled = false, path, callback) {
    const button = document.querySelector("#autoX__strategyBackNav");
    button.disabled = true;

    // Disable next buttons and slider while animation is playing
    const slowNextButton = document.querySelector("#seekStrategySlowNextButton");
    const fastNextButton = document.querySelector("#seekStrategyFastNextButton");
    const slider = document.querySelector("#seekOptionSlider");
    slowNextButton.disabled = true;
    fastNextButton.disabled = true;
    slider.disabled = true;

    if(pastTimelines.length > 0) {
        const sliderCard = document.querySelector("#seekOptionSlider");
        if(pastTimelines.length === 2) {
            sliderCard.disabled = true;

            if(path === 'switching') {
                pastTimelines[pastTimelines.length - 1].pause();
            }

            if(!sliderToggled) {
                optionSelected = false;
                fastPathStarted = false;
                slowPathStarted = false;                
            }
        } else if(pastTimelines.length === 1) {
            pastTimelines[0].seek('spinnersFadeAway+=13');
        }

        pastTimelines[pastTimelines.length - 1].timeScale(2.5);
        pastTimelines[pastTimelines.length - 1].reverse();
        const currentTimelineLength = pastTimelines[pastTimelines.length - 1].time() / 2.5;
        pastTimelines.pop();

        // Disable back button until animation finishes
        setTimeout(() => {
            button.disabled = false;
            sliderCard.disabled = false;
            slowNextButton.disabled = false;
            fastNextButton.disabled = false;
            slider.disabled = false;

            if(path === 'switching') {
                callback();
            }
        }, currentTimelineLength * 1000 );
    } else {
        window.location.href = "/lp/autox/landing";
    }
}


function startSpinnerAnimations(timeline, trigger, offset, stagger) {
    let totalOffset = offset;
    for(let i = 1; i <= 19; i++) {
        totalOffset += stagger;
        timeline.fromTo(`#spinner_${i}`, {scale: 0, transformOrigin: "center"}, {duration: 0.2, scale: 1}, trigger + '+=' + totalOffset).fromTo(`#spinner_${i}`, 
            {duration: 0.8, opacity:1, transformOrigin: "center",},
            {duration: 0.8, rotation:"+=360",repeat:-1, ease: 'linear'}
        )
    }
}

function animatedDottedLine(timeline, parentId, maskPathId, start, end, duration, timing) {
    timeline.to(parentId, {opacity: 1}, timing)
    .fromTo(maskPathId, {
        drawSVG: start,
    }, {
        drawSVG: end,
        duration: duration,
        ease: 'linear'
    }, timing)
}

function transitionStatusText(timeline, firstTextElement, secondTextElement, firstElementTiming, secondElementTiming) {
    timeline.fromTo(firstTextElement, {opacity: 1, display: "block", y:0}, {opacity: 0, y: -60, top: 0, display: "none", duration: 0.6}, firstElementTiming)
    .fromTo(secondTextElement, {opacity: 0, y: 80}, { display: "block", opacity: 1, y: 0, duration: 0.6}, secondElementTiming)
}

function moveNavArrow(timeline, arrowId, pathId, duration, start, end, timing, changeRotation = false) {
    timeline.to(arrowId, {
        duration: duration,
        ease: 'linear',
        motionPath: {
            path: pathId,
            align: pathId,
            type: "cubic",
            autoRotate: true,
            alignOrigin: [0.5, 0.5],
            start: start,
            end: end
        }
    }, timing);
}
