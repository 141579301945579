/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-useless-constructor */
/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unescaped-entities */
import React, {useRef, useEffect, useState, Suspense} from 'react';

const ContactUs = () => {

    return (
        <div className="autoX__contactUsPage">

            <section className="autoX__contactUsEmbed">
                <div className="container">
                    <iframe id="autoX__contactIframeDesktop" width="100%" height="580" frameborder="0" src="https://marketaxess.tfaforms.net/219"></iframe>
                    <iframe id="autoX__contactIframeMobile" width="100%" height="600" frameborder="0" src="https://marketaxess.tfaforms.net/218"></iframe>
                </div>
            </section>

        </div>
    );
    
}

export default ContactUs;
