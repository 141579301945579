import React from 'react';

const StrategyActionCards = ({strategyType}) => {


    if(strategyType === 'take') {
       return (
        <div className="autoX__strategyActions">
            <div className="autoX__strategyActionsPrompt" id="takeStrategyPrompt1">
                <span className="autoX__strategyActionsCardText">Options to modify on the fly</span>
                <span className="autoX__strategyActionsDottedLine" id="takeStrategyPromptDottedLine1"></span>
            </div>

            <div className="autoX__strategyActionsOption" id="takeStrategyOption1">
                <span className="autoX__strategyActionsCardSubtitle" id="takeStrategyOption1Subtitle">Option 1</span>

                <div id="autoX__strategyActionsCardText-1-1">
                    <span className="autoX__strategyActionsCardText">Switch to passive trading and wait</span>
                    <button className="autoX__strategyActionsCardButton" id="takeStrategyOptionButton1">
                        Rest Order<span className="autoX__strategyActionsCardButtonArrow"></span>
                    </button>
                </div>

                <div id="autoX__strategyActionsCardText-1-2">
                    <span className="autoX__strategyActionsCardText">Switched to waiting and trading passively</span>
                </div>

                <div id="autoX__strategyActionsCardText-1-3">
                    <span className="autoX__strategyActionsCardText">Retrying the order</span>
                </div>

                <span className="autoX__yellowGlowingCircle"></span>
                <span className="autoX__strategyActionsDottedLine" id="takeStrategyPromptDottedLine2"></span>
            </div>

            <div className="autoX__strategyActionsOption" id="takeStrategyOption2">
                <span className="autoX__strategyActionsCardSubtitle">Option 2</span>
                <span className="autoX__strategyActionsCardText">Retry if responses are sub-optimal</span>
                <button className="autoX__strategyActionsCardButton" id="takeStrategyOptionButton2">
                    Retry Order<span className="autoX__strategyActionsCardButtonArrow"></span>
                </button>
                <span className="autoX__yellowGlowingCircle"></span>
            </div>

            <div className="autoX__strategyActionsOption" id="takeStrategyOption4">
                <span className="autoX__strategyActionsCardText">Algorithmic trading under your command</span>
                <a href="/lp/autox/ending" className="autoX__strategyActionsCardButton" id="takeStrategyOptionButton3">
                    Learn More<span className="autoX__strategyActionsCardButtonArrow"></span>
                </a>
            </div>

            <div className="autoX__strategyActionsOption autoX__strategyActionsOption--alt" id="takeStrategyOption3">
                <span className="autoX__strategyActionsCardText">If unsuccessful at first, the strategy can be configured to reattempt a specified number of times, providing liquidity or engaging in passive opportunities while in a resting state</span>
            </div>

            <button className="autoX__strategyActionsNextButton" id="takeStrategyNextButton">
                Next<span className="autoX__strategyActionsNextButtonArrow"></span>
            </button>
        </div>
       )
    }

    if(strategyType === 'seek') {
        return (
            <div className="autoX__strategyActions">
                <div className="autoX__strategyActionsPrompt" id="takeStrategyPrompt1">
                    <span className="autoX__strategyActionsCardText">Options to modify on the fly</span>
                    <span className="autoX__strategyActionsDottedLine" id="takeStrategyPromptDottedLine1"></span>
                </div>

                <div className="autoX__strategyActionsOption autoX__strategyActionsOption--slider" id="seekStrategyOption1">
                    <span className="autoX__strategyActionsCardSubtitle">Option</span>
                    <span className="autoX__strategyActionsCardText">Adjust your urgency whenever you need</span>
                    <div className="seekOptionSliderContainer">
                        <input type="range" min="1" max="100" className="seekOptionSlider" id="seekOptionSlider"/>
                    </div>
                    <div className="seekSliderLabels">
                        <span>Slower</span>
                        <span>Faster</span>
                    </div>
                    <span className="autoX__yellowGlowingCircle"></span>
                </div>

                <div className="autoX__strategyActionsOption autoX__strategyActionsOption--textOnly" id="seekStrategyOption2">
                    <span className="autoX__strategyActionsCardText">Decide how often you want to aggress the market within your price, size and time constraints</span>
                </div>

                <div className="autoX__strategyActionsOption" id="seekStrategyOption3">
                    <span className="autoX__strategyActionsCardText">Algorithmic trading under your command</span>
                    <a href="/lp/autox/ending" className="autoX__strategyActionsCardButton" id="takeStrategyOptionButton3">
                        Learn More<span className="autoX__strategyActionsCardButtonArrow"></span>
                    </a>
                </div>

                <button className="autoX__strategyActionsNextButton" id="seekStrategySlowNextButton">
                    Next<span className="autoX__strategyActionsNextButtonArrow"></span>
                </button>

                <button className="autoX__strategyActionsNextButton" id="seekStrategyFastNextButton">
                    Next<span className="autoX__strategyActionsNextButtonArrow"></span>
                </button>

                <button className="autoX__strategyActionsNextButton" id="seekStrategyEndButton">
                    Next<span className="autoX__strategyActionsNextButtonArrow"></span>
                </button>
            </div>
        )
    }

    
    if(strategyType === 'provide') {
       return (
        <div className="autoX__strategyActions">
                <div className="autoX__strategyActionsPrompt" id="takeStrategyPrompt1">
                    <span className="autoX__strategyActionsCardText">Options to modify on the fly</span>
                    <span className="autoX__strategyActionsDottedLine" id="takeStrategyPromptDottedLine1"></span>
                </div>

                <div className="autoX__strategyActionsOption" id="provideStrategyOption1">
                    <span className="autoX__strategyActionsCardSubtitle">Option</span>

                    <div id="autoX__strategyActionsCardText-1-1">
                        <span className="autoX__strategyActionsCardText">Take your order out of the market</span>
                        <button className="autoX__strategyActionsCardButton" id="provideStrategyOptionButton1">
                            Pause Order<span className="autoX__strategyActionsCardButtonPause"></span>
                        </button>
                    </div>

                    <div id="autoX__strategyActionsCardText-1-2">
                        <span className="autoX__strategyActionsCardText">Order paused</span>
                    </div>

                    <div id="autoX__strategyActionsCardText-1-3">
                        <span className="autoX__strategyActionsCardText">Re-enter the market with a single click</span>
                    </div>

                    <button className="autoX__strategyActionsCardButton" id="provideStrategyOptionButton2">
                        Resume Order<span className="autoX__strategyActionsCardButtonArrow"></span>
                    </button>

                    <span className="autoX__yellowGlowingCircle"></span>
                </div>


                <div className="autoX__strategyActionsOption autoX__strategyActionsOption--slider autoX__strategyActionsOption--sliderSm" id="provideStrategyOption2">
                    <span className="autoX__strategyActionsCardSubtitle">Option</span>
                    <span className="autoX__strategyActionsCardText">Time to tighten your level</span>
                    <div className="seekOptionSliderContainer">
                        <input type="range" min="1" max="100" className="seekOptionSlider" id="provideOptionSlider"/>
                    </div>
                    <div className="seekSliderLabels">
                        <span>$ -</span>
                        <span>$ +</span>
                    </div>
                    <span className="autoX__yellowGlowingCircle"></span>
                </div>

                <button className="autoX__strategyActionsNextButton" id="provideStrategyNextButtonLess">
                    Next<span className="autoX__strategyActionsNextButtonArrow"></span>
                </button>

                <button className="autoX__strategyActionsNextButton" id="provideStrategyNextButtonMore">
                    Next<span className="autoX__strategyActionsNextButtonArrow"></span>
                </button>

                <div className="autoX__strategyActionsOption autoX__strategyActionsOption--textOnly" id="provideStrategyOption3">
                    <span className="autoX__strategyActionsCardText">The strategy will continue to work your residual order according to your settings. Unless you opt to “cleanup” via RFQ.</span>
                </div>

                <button className="autoX__strategyActionsNextButton" id="provideStrategyEndingButton">
                    Next<span className="autoX__strategyActionsNextButtonArrow"></span>
                </button>

                <div className="autoX__strategyActionsOption" id="provideStrategyOption4">
                    <span className="autoX__strategyActionsCardText">Algorithmic trading under your command</span>
                    <a href="/lp/autox/ending" className="autoX__strategyActionsCardButton" id="provideStrategyOptionButton2">
                        Learn More<span className="autoX__strategyActionsCardButtonArrow"></span>
                    </a>
                </div>
            </div>
       )
    }
}


export default StrategyActionCards;
